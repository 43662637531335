<template>
  <v-row class="">
    <v-col cols="12" class="text text-5 normal-label pb-2 pl-0">
      Custo total das tarifas atuais por período
      <tool-tip
        :helpText="'Custo final incluíndo também já custos de ligação à rede e de potência em horas de ponta.'"
      />
    </v-col>
    <v-row class="pt-0 px-3">
      <v-col cols="12">
        <v-row>
          <v-col
            class="px-3 pt-4 pb-0 justify-space-between"
            :class="[class1(index), class2($vuetify, index)]"
            :cols="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 3 : 6"
            :key="index"
            v-for="(tariff, index) in tariff_info.tariffs"
          >
            <v-row class="px-0 py-0 text tarif-header-area mx-0 my-0 rounded-t">
              <v-col
                class="d-flex justify-center text-tarif text-5 table-header"
                >{{ tariff.text }}</v-col
              >
            </v-row>
            <v-row class="mt-0 mx-0 pb-2 d-flex">
              <v-col
                class="d-flex py-2 justify-center text text-5 textbg rounded-b"
                >{{ tariff.total }} €/kWh</v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import ToolTip from "../general/tool-tip.vue";

export default mixins(General).extend({
  name: "TariffSummary",
  components: { ToolTip },
  methods: {
    class1(index: number): string {
      return index % 2 == 0 ? "pl-0" : "pr-0";
    },
    class2(vuetify: any, index: number): string {
      return (vuetify.breakpoint.lg || vuetify.breakpoint.xl) &&
        (index == 1 || index == 2)
        ? "pl-3 pr-3"
        : "";
    },
  },
});
</script>

<style scoped lang="scss">
.summary-tariff-area {
  background-color: rgba(59, 187, 200, 0.05);
  border: solid 1px #47aea5;
  border-radius: 5px;
}

.textbg {
  background-color: #fff !important;
}
</style>