<template>
  <v-row>
    <v-col cols="12" class="text table-header-area">
      <v-row class="text text-6 table-header">
        <v-col cols="6">Condómino</v-col>
        <v-col cols="3">kWh</v-col>
        <v-col cols="3">A Pagar</v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row class="text text-5" :key="index" v-for="(value, index) in users">
        <v-col v-if="value" cols="6">{{ value.user }}</v-col>
        <v-col v-if="value.totals" cols="3" class="left-liner right-liner">{{
          value.totals.total_energy
        }}</v-col>
        <v-col v-if="value.totals" cols="3">{{ `${value.totals.total_cost} €` }}</v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "ChargingSessionTable",
  props: ["users"],
});
</script>
