<template>
  <v-app>
    <v-main>
      <router-view />
      <cookie-snackbar />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import cookieSnackbar from "./components/general/cookie-snackbar.vue";

export default Vue.extend({
  components: { cookieSnackbar },
  name: "App",

  data: () => ({
    //
  }),
});
</script>

<style lang="scss">
@import url("scss/global.scss");
</style>
