<template>
  <v-row class="px-0 py-0 mx-0 my-0">
    <v-col cols="12" class="pt-0 px-0 pb-0">
      <tariff-cycle @checkMethod="checkSubmitButton" @updateValidation="updateValidation" :showButtonErro="showButtonErro"/>
    </v-col>
    <v-col cols="12" class="pt-0 px-0">
      <form-select
        :field_type="'string'"
        :period="0"
        :required="true"
        :field_key="'plan'"
        :title="'Indique o nível de tensão da eletricidade do condomínio'"
        :items="[
          { value: '0', text: 'Baixa Tensão Normal (BTN)' },
          { value: '1', text: 'Baixa Tensão Especial (BTE)' },
          { value: '2', text: 'Media Tensão (MT)' },
          { value: '3', text: 'Alta Tensão (AT)' },
        ]"
        :placeholder="'Selecionar Tensão'"
        @clearFormData="clearFormData"
      />
    </v-col>
    <v-col cols="12" class="pt-4 px-0">
      <form-textbox
        :field_key="'contracted_power'"
        :required="true"
        :period="0"
        :title="'Indique a potência contratada pelo condomínio'"
        :placeholder="'Potência Contratada (ex.: 0.0)'"
        :field_type="'float'"
        :suffix="'kVA'"
        :bold="true"
        :title_alone="false"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import editModal from "../general/edit-modal.vue";
import TariffCycle from "./tariff-cycle.vue";
import FormSelect from "../forms/form-select.vue";
import FormTextbox from "../forms/form-textbox.vue";
import FormRadio from "../forms/form-radio.vue";

export default mixins(General).extend({
  components: { editModal, TariffCycle, FormSelect, FormTextbox, FormRadio },
  props: ["checkSubmitButton","showButtonErro"],
  name: "TariffData",
  methods: {
    clearFormData(){
      this.$emit('clearFormData');
    },
    updateValidation(){
      this.$emit('updateValidation', false);/* this goes to the tariff.vue to change the value of the button to remove the colour red on the button */
    }
  },
});
</script>
