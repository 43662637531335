<template>
  <v-dialog v-model="modalShow" max-width="570px" content-class="simple-content-modal external rounded-xl mx-10">
    <v-card class="d-flex flex-column align-center">
      <v-icon class="ml-auto mt-3 mr-4" @click="closeModal(modalName)">mdi-close
      </v-icon>
      <div class="align-center mt-n3">
        <img :src="getIcon(icon)" :alt="alt" />
      </div>
      <div class="modal-header font-weight-bold mt-3 d-flex text-center align-center">
        <p class="header-4 general-modal-header mb-0">
          {{ header }}
        </p>
      </div>
      <div class="d-flex align-center text-center">
        <span class="pt-2 pr-8 pb-6 pl-8">{{ text }}</span>
      </div>
      <div class="d-flex align-center">
        <button class="tarif-button rounded mb-6 font-weight-bold" @click="
          closeModal(modalName),
          modalName == 'update-tariff-info' ? callSubmitEvent() : null;
        modalName == 'edit-tariff-info' ? closeModal('edit-modal') : null;
        ">
          OK
        </button>
      </div>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "../../mixins/general";
export default mixins(General).extend({
  name: "SuccessModal",
  props: ["modalName", "text", "header", "alt", "icon"],
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
    },
  },
  methods: {
    callSubmitEvent(): void {
      this.$emit("callEvent");
    },
  },
});
</script>

<style lang="scss" scoped>
.simple-content-modal {
  position: relative;
  min-width: 350px;
  background-color: rgba(107, 107, 107, 0.293);
}

.tarif-button {
  width: 120px;
  height: 50px;
  background-color: #47aea5;
  color: white;
}
</style>
