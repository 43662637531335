<template>
  <v-row>
    <v-col cols="12" class="header-3 font-weight-bold text-left"
      >A minha conta</v-col
    >
    <v-col cols="12" class="text-start pt-0 pb-0"
      >Verifique aqui as informações do condomínio selecionado, da sua conta
      e/ou adicione novos dados fiscais.
    </v-col>

    <v-col cols="12" class="pt-0 pb-0">
      <dashboard-mode
        :tab1="
          this.condos_response.selected_role != 'user' ? 'Condomínio' : null
        "
        :tab2="'Pessoal'"
        :tab3="'Acesso'"
        :profile="true"
      />
    </v-col>
    <div class="col-12 px-0" v-if="active_tab_profile == 0">
      <form-Condominio/>
    </div>
    
    <div class="col-12 px-0" v-if="active_tab_profile == 1">
      <form-Pessoal/>
    </div>

    <div class="col-12 px-0" v-if="active_tab_profile == 2">
       <form-Acesso/>
    </div>

    <success-modal
      :modal-name="'handle-condos-profile'"
      :header="'Alterações gravadas!'"
      :text="'As tuas Alterações foram gravadas.'"
      :icon="'Union.svg'"
    />
    <success-modal
      :modal-name="'handle-user-profile'"
      :icon="'Union.svg'"
      :header="'Alterações gravadas!'"
      :text="'As tuas Alterações foram gravadas.'"
    />

  </v-row>
</template>

<script lang="ts">

import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import formTextbox from "@/components/forms/form-textbox.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import DashboardMode from "@/components/dashboard/dashboard-mode.vue";
import SuccessModal from "@/components/general/success-modal.vue";
import formAcesso from "@/components/forms/form-acesso.vue"
import formPessoal from "@/components/forms/form-pessoal.vue"
import formCondominio from "@/components/forms/form-condominio.vue"
export default mixins(General).extend({
  components: { formTextbox, SubmitButton, DashboardMode, SuccessModal, formAcesso,formPessoal,formCondominio },
  name: "ManageAccount",
  data() {
    return {
      isChecked: false,
      loading: false,
      hasError: false,
    };
  },
  methods: {},
  computed: {},

});
</script>

<style lang="scss">
#chk1 {
  accent-color: green;
}

.test-animation {
  animation: slide-down 100ms ease-out;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 22px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.1s;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #00bdc9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00bdc9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #39393d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.v-input {
  display: flex;
  align-items: center;
}

.button-manage {
  width: 100%;
  border: 1px solid $blue-1;
  background-color: #fff;
  color: $blue-1;
  height: 36px;
  font-weight: bold;
}
</style>
