<template>
  <v-row class="pb-4">
    <v-col cols="12" class="text-left header header-3 pt-0 pb-2">
      Sessões de Carregamento
    </v-col>
    <v-col cols="12" class="pt-2">
      <v-card class="col-12 rounded-lg">
        <v-row>
          <v-col cols="12" class="text">
            <!-- Charging session table no data-->
            <charging-session-table-no-data />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import ChargingSessionTableNoData from "./charging-session-table-no-data.vue";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "CharginSessionNoData",
  components: {
    ChargingSessionTableNoData,
  },
});
</script>
