<template>
  <v-row>
    <v-col
      cols="12"
      :class="this.consumo == true ? 'pt-0' : 'pt-3'"
      class="text-left text normal-label pb-0"
    >
      Selecione um mês de faturação do Condomínio
    </v-col>
    <v-col class="pt-1 pb-0 clickable" @click="displayDateModal(true)">
      <v-text-field
        color="#c9c9c9"
        outlined
        dense
        background-color="#ffffff"
        item-color="#000000"
        class="text text-5 text-prefix text-selected"
        :value="
          this.s_date.display_format && this.e_date.display_format
            ? `${this.start_date()} a ${this.end_date()}`
            : ''
        "
        :disabled="this.billing_periods.length == 0"
      >
        <template v-slot:append>
          <span class="clickable">
            <v-icon color="#a7a7a7" class="send-email-icon">
              mdi-calendar-blank
            </v-icon>
          </span>
        </template>
      </v-text-field>

      <date-modal
        v-if="this.consumption_data && this.billing_periods.length > 0"
        :modalName="'date_modal'"
        :billingStartDate="billingPeriodStartDate"
        :billingEndDate="billingPeriodEndDate"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import DateModal from "@/components/general/date-modal.vue";
import { General } from "@/mixins/general";
import moment from "moment";
declare interface DateOptions {
  text: string;
  value: number;
}

export default mixins(General).extend({
  name: "BillPeriodSelector",
  props: ["consumo"],
  components: {
    DateModal,
  },
  data() {
    return {
      selected_value: 0,
    };
  },
  computed: {
    billingPeriodStartDate(): any {
      return this.setBillingPeriod("start");
    },
    billingPeriodEndDate(): any {
      return this.setBillingPeriod("end");
    },
  },
  methods: {
    start_date() {
      const start_date = moment(this.s_date.send_format).format("DD-MM-YYYY");
      return start_date;
    },

    end_date() {
      const end_date = moment(this.e_date.send_format).format("DD-MM-YYYY");
      return end_date;
    },
    displayDateModal(status: boolean): void {
      this.$store.commit("toggleModalState", {
        status: status,
        name: "date_modal",
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.text-selected {
  color: black !important;
}
</style>
