var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"text-left header header-3 pt-0 pb-0",attrs:{"cols":"12"}},[_vm._v(" Consumos do Período Anterior ")]),(_vm.selected_index == 0 && this.billing_periods[_vm.selected_index + 1])?_c('v-col',{staticClass:"text-left text text-normal pt-1 pb-0",attrs:{"cols":"12"}},[_vm._v(" De "+_vm._s(this.start_date_bp())+" a "+_vm._s(this.end_date_bp())+" ")]):_vm._e(),_c('v-col',{staticClass:"pt-2"},[_c('v-card',{staticClass:"col-12 rounded-lg"},[_c('v-row',[_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('period-before-consumption-stat',{attrs:{"stat_type":'Sessões',"stat_value":this.consumption_data_prev.consumptions.length > 0
                    ? this.consumption_data_prev.consumptions[0].sessions
                        .length
                    : 0}})],1),_c('v-col',{staticClass:"text-center left-liner right-liner"},[_c('period-before-consumption-stat',{attrs:{"stat_type":'Consumo Total',"energy":true,"stat_value":this.consumption_data_prev.consumptions.length > 0
                    ? this.consumption_data_prev.consumptions[0].totals
                        .total_energy
                    : 0}})],1),(this.consumption_data_prev.consumptions.length > 0)?_c('v-col',[_c('period-before-consumption-stat',{attrs:{"stat_type":'Custo Total',"stat_value":`${
                  this.consumption_data_prev.consumptions.length > 0
                    ? this.consumption_data_prev.consumptions[0].totals
                        .total_cost
                    : 0
                } €`}})],1):_vm._e(),(
                this.selected_index == 0 &&
                !this.consumption_data_prev.consumptions[0]
              )?_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('charging-cost-table-no-data')],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[(this.consumption_data_prev.consumptions)?_c('v-col',{staticStyle:{"font-size":"12px"}},[_c('router-link',{attrs:{"to":{ path: '/dashboard/support', query: { cat: 1 } }},on:{"click":function($event){return _vm.handleChangeTab(3)}}},[(this.consumption_data_prev.confirmed == false)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#cc8e67"}},[_vm._v("custo estimado, ")]):_vm._e()]),(this.consumption_data_prev.confirmed == true)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("custo confirmado, ")]):_vm._e(),_c('span',[_vm._v("incluindo IVA taxa legal em vigor")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }