<template>
  <v-row>
    <v-col
      v-if="title"
      cols="12"
      class="text text-5 normal-label pb-0 pt-0 mb-0"
    >
      {{ title }}
    </v-col>
    <v-col cols="12" class="pt-0 pb-0">
      <v-radio-group
        v-if="$route.path != '/dashboard/support' && this.edit != true"
        row
        v-model="field_value"
        class="pt-0 pb-0 my-0 form-radio"
        v-on:change="callMethod(field_value)"
      >
        <v-radio
          :key="index"
          v-for="(itm, index) in items"
          class="pt-0 my-0 mr-0"
          color="#47aea5"
          :value="itm.value"
          :ripple="false"
        >
          <template v-slot:label>
            <div
              class="form-radio pb-1 form-radio-normal"
              v-html="itm.text"
            ></div>
          </template>
        </v-radio>
      </v-radio-group>
      <v-radio-group
        v-if="$route.path != '/dashboard/support' && this.edit == true"
        row
        v-model="edit_value"
        class="pt-0 pb-0 my-0 form-radio"
        v-on:change="callMethod(field_value)"
      >
        <v-radio
          :key="index"
          v-for="(itm, index) in items"
          class="pt-0 my-0 mr-0"
          color="#47aea5"
          :value="itm.value"
          :ripple="false"
        >
          <template v-slot:label>
            <div
              class="form-radio pb-1 form-radio-normal"
              v-html="itm.text"
            ></div>
          </template>
        </v-radio>
      </v-radio-group>
      <v-radio-group
        v-if="$route.path == '/dashboard/support'"
        row
        v-model="faq_value"
        class="pt-0 pb-0 my-0 form-radio"
        v-on:change="callMethod(faq_value)"
      >
        <v-radio
          :key="index"
          v-for="(itm, index) in items"
          class="pt-0 my-0 mr-0"
          color="#47aea5"
          :value="itm.value"
          :ripple="false"
        >
          <template v-slot:label>
            <div
              class="form-radio pb-1 form-radio-normal"
              v-html="itm.text"
            ></div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "FormRadio",
  props: ["title", "items", "edit"],
});
</script>
<style scoped lang="scss">
.form-radio-normal {
  margin: 5px 10px 0 0;
}

.form-radio {
  color: #000000;
  line-height: 1.1;
}
</style>
