<template>
  <v-row>
    <v-col
      :class="stat_type == 'Consumo Total' ? 'px-1' : ''"
      class="text-center"
    >
      <div>
        <img
          :class="
            stat_type === 'kWh' || stat_type === 'Consumos Totais'
              ? 'thunder-png'
              : 'info-icon-img'
          "
          :src="getIcon()"
        />
      </div>
      <div class="dull-label">{{ stat_type }}</div>
      <div class="text text-1 numeric">
        {{ stat_value }}
        <span style="font-size: 18px" v-if="this.energy == true">kWh</span>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "ActualConsumptionStat",
  props: ["stat_type", "stat_value", "energy"],
});
</script>
<style scoped lang="scss">
@media screen and (max-width: 320px) {
  .thunder-png {
    width: 17px;
    height: 24px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .thunder-png {
    width: 19.9px;
    height: 28px;
  }
}

@media screen and (min-width: 600px) {
  .thunder-png {
    width: 22.7px;
    height: 32px;
  }
}
</style>
