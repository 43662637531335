<template>
  <v-row class="pb-4">
    <v-col cols="12" class="text-left header header-3 pt-0 pb-0">
      Sessões de Carregamento
    </v-col>
    <!-- <v-col cols="12" class="text-left text text-normal pt-1 pb-0">
      A mostrar {{ start + 1 }} -
      {{
        this.consumption_data.consumptions[0].sessions.length > counter + 5
        ? counter + 5
        : this.consumption_data.consumptions[0].sessions.length
      }}
      de
      {{ this.consumption_data.consumptions[0].sessions.length }}
    </v-col> -->
    <v-col cols="12" class="pt-2">
      <v-card class="col-12 rounded-lg">
        <v-row>
          <v-col cols="12" class="text">
            <!-- Charging session table -->
            <charging-session-table />
          </v-col>
        </v-row>
      </v-card>
      <v-row cols="px-0 py-0">
        <v-col
          class="mt-3"
          style="font-size: 12px"
          cols="12"
          v-if="this.consumption_data.confirmed == true"
        >
          <span>Incluindo IVA à taxa legal em</span>
          vigor.
        </v-col>
        <v-col class="mt-3" style="font-size: 12px" cols="12" v-else>
          <router-link
            @click="handleChangeTab(3)"
            :to="{ path: '/dashboard/support/', query: { cat: 1 } }"
          >
            <span style="color: black" class="font-weight-bold"
              >custos estimados</span
            ></router-link
          ><span>, incluindo IVA à taxa legal em</span>
          vigor.
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-col cols="6" class="pt-0">
      <v-btn depressed dense color="#47aea5" class="charging-table-button" rounded-md block @click="prev"
        :disabled="!prev_enabled">
        <div class="button button-text-1">Anterior</div>
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-col>
    <v-col cols="6" class="pt-0">
      <v-btn depressed dense color="#47aea5" class="charging-table-button" rounded-md block :disabled="!next_enabled"
        @click="next">
        <div class="button button-text-1">Seguinte</div>
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-col> -->
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import ChargingSessionTable from "@/components/dashboard/charging-session-table.vue";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "CharginSession",
  components: {
    ChargingSessionTable,
  },
  methods: {
    next() {
      let n_start = this.start;
      let n_counter = this.counter;
      if (this.consumption_data.consumptions[0].sessions.length > n_counter) {
        n_start = n_start + this.length;
      }
      if (n_start > this.consumption_data.consumptions[0].sessions.length) {
        n_counter = this.consumption_data.consumptions[0].sessions.length;
      } else {
        n_counter = n_start;
      }
      this.checkButton(n_start, n_counter);
    },
    prev() {
      let n_start = this.start;
      let n_counter = this.counter;
      n_start = n_start - this.length;
      if (n_start <= 0) {
        n_start = 0;
        n_counter = 0;
      } else {
        n_counter = n_start;
      }
      this.checkButton(n_start, n_counter);
    },
  },
});
</script>
