<template>
  <div class="d-flex calendar">
    <custom-date-picker
      @dateSelected="setDate($event)"
      :modalName="modalName"
      :billingStartDate="billingStartDate"
      :billingEndDate="billingEndDate"
    />
  </div>
</template>

<script>
import CustomDatePicker from "@/components/general/custom-date-picker.vue";
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "CalendarPicker",
  props: ["modalName", "billingStartDate", "billingEndDate"],
  components: {
    CustomDatePicker,
  },
});
</script>

<style lang="scss" scoped>
.calendar {
  width: 100%;
  overflow: hidden;
}
</style>