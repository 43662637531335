var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[(_vm.title)?_c('v-col',{staticClass:"text text-5 normal-label pb-0 pt-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-col',{staticClass:"pt-1",attrs:{"col":"12"}},[(
        _vm.$route.path != '/dashboard/support' &&
        _vm.type != 'Box' &&
        this.edit != true
      )?_c('v-select',{staticClass:"filled-input",attrs:{"items":_vm.items,"placeholder":_vm.placeholder,"outlined":"","dense":"","height":"45","background-color":"#ffffff","item-color":"#2e3335","rules":[v => !!v || 'Por favor preencha este campo']},on:{"change":[_vm.callMethod,_vm.clearData]},model:{value:(_vm.field_value),callback:function ($$v) {_vm.field_value=$$v},expression:"field_value"}}):_vm._e(),(
        _vm.$route.path != '/dashboard/support' &&
        _vm.type != 'Box' &&
        this.edit == true
      )?_c('v-select',{staticClass:"filled-input",attrs:{"items":_vm.items,"placeholder":_vm.placeholder,"outlined":"","dense":"","height":"45","background-color":"#ffffff","item-color":"#2e3335","hide-details":""},on:{"change":[_vm.callMethod,_vm.clearData]},model:{value:(_vm.edit_value),callback:function ($$v) {_vm.edit_value=$$v},expression:"edit_value"}}):_vm._e(),(_vm.$route.path == '/dashboard/support')?_c('v-select',{staticClass:"filled-input",attrs:{"disabled":this.disabled,"items":_vm.items,"placeholder":_vm.placeholder,"outlined":"","dense":"","height":"45","background-color":this.disabled == true ? '#DFDFDF' : '#ffffff',"item-color":"#2e3335","hide-details":""},on:{"input":_vm.check,"change":_vm.callMethod},model:{value:(_vm.faq_value),callback:function ($$v) {_vm.faq_value=$$v},expression:"faq_value"}}):_vm._e(),(_vm.type == 'Box')?_c('v-select',{staticClass:"filled-input",attrs:{"multiple":_vm.type == 'Box' ? true : false,"items":_vm.items,"placeholder":_vm.placeholder,"outlined":"","dense":"","height":"45","background-color":"#ffffff","item-color":"#2e3335","hide-details":""},on:{"change":_vm.callMethod},scopedSlots:_vm._u([(_vm.type == 'Box' ? true : false)?{key:"append",fn:function(){return [_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/icons/hub-icon.svg"),"alt":"Custom Icon"}})]},proxy:true}:null],null,true),model:{value:(_vm.condo_value),callback:function ($$v) {_vm.condo_value=$$v},expression:"condo_value"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }