<template>
  <div class="video-container">
    <iframe
      :src="videoSrc"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.video-container {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
