
<!--  Custom Date picker Open source needs customization-->
<template class="calendar-picker">
  <div class="cd-wrapper">
    <div class="cd-body-wrapper">
      <div class="d-flex align-center justify-space-between mx-4 mt-2">
        <div @click.stop.prevent="changeBillingPeriod('next')" :class="nextDisabled == true ? 'blocks' : 'blocks-enabled'"
          class="clickable d-flex align-center justify-center">
          <button class="pl-1 clickable">
            <figure :class="nextDisabled == true ? 'chevron' : 'chevron-enabled'" class=" left"></figure>
          </button>
        </div>
        <span class="date-time font-weight-bold">
          {{
            billingPeriodStartDate.date() +
            " " +
            translateMonthName(billingPeriodStartDate.month() + 1)
          }}
          -
          {{
            billingPeriodEndDate.date() +
            " " +
            translateMonthName(billingPeriodEndDate.month() + 1)
          }}
        </span>
        <div @click.stop.prevent="changeBillingPeriod('prev')" :class="prevDisabled == true ? 'blocks' : 'blocks-enabled'"
          class="clickable d-flex align-center justify-center">
          <button class="pr-1 clickable">
            <figure :class="prevDisabled == true ? 'chevron' : 'chevron-enabled'" class="right"></figure>
          </button>
        </div>
      </div>
      <div :class="$vuetify.breakpoint.md ? 'justify-space-between' : ''"
        class="d-flex align-center justify-space-around">
        <div class="d-md-none d-flex align-center justify-space-around days mt-2">
          <span class="ml-3">Dom</span>
          <span>Seg</span>
          <span>Ter</span>
          <span>Qua</span>
          <span>Qui</span>
          <span>Sex</span>
          <span class="mr-3">Sab</span>
        </div>
        <div class="d-none d-md-flex align-center justify-space-between days1 px-7 mt-2">
          <span class="day d-flex justify-center">Dom</span>
          <span class="day d-flex justify-center">Seg</span>
          <span class="day d-flex justify-center">Ter</span>
          <span class="day d-flex justify-center">Qua</span>
          <span class="day d-flex justify-center">Qui</span>
          <span class="day d-flex justify-center">Sex</span>
          <span class="day d-flex justify-center">Sab</span>
        </div>
        <div class="d-none d-md-flex align-center justify-space-between days1 px-7 mt-2">
          <span class="day d-flex justify-center">Dom</span>
          <span class="day d-flex justify-center">Seg</span>
          <span class="day d-flex justify-center">Ter</span>
          <span class="day d-flex justify-center">Qua</span>
          <span class="day d-flex justify-center">Qui</span>
          <span class="day d-flex justify-center">Sex</span>
          <span class="day d-flex justify-center">Sab</span>
        </div>
      </div>
      <!-- use to be space around -->
      <v-row class="overflow-y-auto container-dates overflow-x-hidden px-0 py-o mx-0 my-0" :class="{
        'overflow-auto': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm,
      }">
        <v-col class="px-0 mx-0 my-0 py-0"><v-row class="px-0 mx-0 my-0 py-0">
            <v-col cols="12" md="6" v-for="(value, index) in $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.xs
              ? this.otherDays
              : this.displayedPages" :key="index" class="px-5" :class="{
    'container-dates heights px-2':
      $vuetify.breakpoint.md ||
      $vuetify.breakpoint.lg ||
      $vuetify.breakpoint.xl,
  }">
              <div class="mt-4 ml-0 font-weight-medium justify-start">
                {{
                  value[0].month() + 1 > 12
                  ? translateMonth(1)
                  : translateMonth(value[0].month() + 1)
                }}
                {{
                  value[0].month() + 1 > 12
                  ? value[0].year() + 1
                  : value[0].year()
                }}
              </div>
              <section>
                <div class="cd-days" data-transition="month-change">
                  <button class="d-flex justify-center" v-for="(day, i) in value" :key="i" :class="calcDayClass(day)"
                    @click.stop.prevent="changeCustomDateRange(day)">
                    <span>{{ day.format("D") }}</span>
                    <figure :style="{
                      background: primaryColor,
                    }"></figure>
                  </button>
                </div>
              </section>
            </v-col> </v-row></v-col>
        <v-col cols="12" class="px-2 pt-3 pb-3 d-none d-md-flex justify-center">
          <v-row class="d-flex justify-center">
            <div class="d-flex" v-if="this.otherDays.length > 2">
              <div v-for="n in this.totalPages" :key="n">
                <div @click="getPage(n)" class="d-flex clickable mr-2" :class="n == currentPage ? 'dot2' : 'dot'"></div>
              </div>
            </div>
            <v-col cols="12" class="bottom-liner-v2"></v-col>
          </v-row>
        </v-col>
        <div style="width: 100%" class="d-flex my-2 ml-5 font-weight-medium justify-start justify-md-center">
          <span class="mr-1">Seleção: </span>
          <span class="font-weight-bold">
            {{
              `${this.selectedStartDate.date()} ${this.translateMonth(
                this.selectedStartDate.month() + 1
              )} - ${this.selectedEndDate ? this.selectedEndDate.date() : "Data Fim"
                } ${this.translateMonth(
                  this.selectedEndDate ? this.selectedEndDate.month() + 1 : null
                )}`
            }}
          </span>
        </div>
        <div style="width: 100%" class="d-flex justify-center mb-5 px-5">
          <button class="reset mr-2 text-4 clickable" @click="resetToBillingPeriod()">
            Repor Período Atual
          </button>
          <button @click="setSelectedPeriod(selectedStartDate, selectedEndDate)" class="submit text-4 clickable">
            Confirmar Seleção
          </button>
        </div>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import moment, { MomentInput } from "moment";
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "CustomDatePicker",
  props: ["modalName", "billingStartDate", "billingEndDate"],
  data() {
    return {
      monthUpdate: 0,
      primaryColor: "#47aea5",
      weekDay: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
      billingPeriodStartDate: this.billingStartDate,
      billingPeriodEndDate: this.billingEndDate,
      selectedStartDate: this.billingStartDate,
      selectedEndDate: this.billingEndDate,
      tempStartDate: null,
      tempEndDate: null,
      customSelectedDate: "",
      itemsPerPage: 2,
      currentPage: 1,
    };
  },
  computed: {
    prevDisabled(): any {
      return (
        this.selected_index == 0
      )
    },
    nextDisabled(): any {
      return (
        this.selected_index == this.billing_periods.length - 1
      )
    },
    diffDays(): any {
      return (
        this.billingPeriodEndDate.diff(this.billingPeriodStartDate, "days") + 30
      )
    },
    addMonth(): any {
      return (
        this.billingPeriodEndDate.month() -
        this.billingPeriodStartDate.month() -
        1
      );
    },

    totalPages(): any {

      return Math.ceil(this.otherDays.length / this.itemsPerPage);
    },

    displayedPages(): any {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.otherDays.slice(startIndex, endIndex);
    },

    monthDays(): any {
      return this.daysConstructor();
    },
    otherDays(): any {
      const groupedArray: any = [];
      let currentMonth: any = null;
      let currentGroup: any = null;
      const filtArray = this.monthDays.filter((el: any) => {

        return el.disabled == false
      });
      filtArray.forEach((date: any) => {
        const month = date.month();
        if (month !== currentMonth) {
          currentMonth = month;
          currentGroup = [];
          groupedArray.push(currentGroup);
        }
        currentGroup.push(date);
      });
      return groupedArray;
    },
  },
  methods: {
    getPage(n: any): any {
      this.currentPage = n;
    },
    daysConstructor(): any {
      let days = [];
      let day_month = this.billingPeriodStartDate.clone();
      day_month = day_month.startOf("month");

      for (let i = 0; i <= this.diffDays + 30; i++) {
        const now_day_month = moment(day_month);
        const properties = {
          disabled: this.isDisabled(now_day_month),
          active: this.isActiveDay(now_day_month),
          resetActive: this.isActiveReset(now_day_month),
          firstDay: this.isCheckData(now_day_month, "first day"),
          firstMonth: this.isCheckData(now_day_month, "first month"),
          lastDay: this.isCheckData(now_day_month, "last day"),
          activeRangeStart_toggle: this.isActiveRangeDay(now_day_month, true),
          activeRangeStart:
            this.isActiveRangeDay(now_day_month, true) &&
            this.isWithinActiveRange(now_day_month),
          activeRangeEnd: this.isActiveRangeDay(now_day_month, false),
          withinActiveRange: this.isWithinActiveRange(now_day_month),
          isNotWithinActiveRange: this.isNotWithinActiveRange(now_day_month),
          today:
            now_day_month.format("MM-DD-YYYY") ===
            moment().format("MM-DD-YYYY"),
        };
        const dayObj = Object.assign(now_day_month, properties);
        days.push(dayObj);

        day_month.add(1, "days");
      }
      const effectiveDays = days.filter((day: any) => {
        return !day.disabled;
      });
      return effectiveDays;
    },
    isActiveDay(now_day_month: any): any {
      return (
        now_day_month.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")
      );
    },
    isActiveRangeDay(dayMoment: any, isStart: any) {
      const formattedDate = dayMoment.format("MM-DD-YYYY"),
        formattedDateStart = moment(this.selectedStartDate).format(
          "MM-DD-YYYY"
        ),
        formattedDateEnd = moment(this.selectedEndDate).format("MM-DD-YYYY");
      const eqSelectedDateStart = formattedDate === formattedDateStart,
        eqSelectedDateEnd = formattedDate === formattedDateEnd;
      return isStart ? eqSelectedDateStart : eqSelectedDateEnd;
    },
    isActiveReset(day: any) {
      return (
        day.format("MM-DD-YYYY") ==
        this.billingPeriodEndDate.format("MM-DD-YYYY") &&
        day.format("MM-DD-YYYY") !=
        moment(this.selectedEndDate).format("MM-DD-YYYY")
      );
    },
    isWithinActiveRange(dayMoment: any) {
      return (
        dayMoment.diff(this.selectedStartDate, "days") >= 0 &&
        dayMoment.diff(this.selectedEndDate, "days") <= 0
      );
    },
    isNotWithinActiveRange(dayMoment: any) {
      return (
        dayMoment.diff(this.billingPeriodStartDate, "days") < 0 ||
        dayMoment.diff(this.billingPeriodEndDate, "days") > 0
      );
    },
    isDisabled(dayMoment: any) {

      //Check if its day is before bp_end_date
      const isBefore = dayMoment.isBefore(this.billingPeriodStartDate);
      //Check if its day is after bp_end_date
      const isAfter = dayMoment.isAfter(this.billingPeriodEndDate);
      //Check if month is a month that is either the one of bpstartdate or bpenddate
      const isMonthGood = dayMoment.month() == this.billingPeriodStartDate.month() || dayMoment.month() == this.billingPeriodEndDate.month()


      return (
        ((isBefore || isAfter) && !isMonthGood)
      );
    },

    isCheckData(moment_date: any, check_type = "first month") {
      switch (check_type) {
        case "first month":
          return moment_date.month() === this.billingPeriodStartDate.month();
        case "first day":
          return parseInt(moment_date.date()) === 1;
        case "last day":
          return (
            moment_date.format("DD") ===
            moment_date.clone().endOf("month").format("DD")
          );
      }
    },
    calcDayClass(day: any) {
      return {
        "last-seg": day.lastDay && this.weekDay[day.day()] === "seg",
        "last-ter": day.lastDay && this.weekDay[day.day()] === "ter",
        "last-qua": day.lastDay && this.weekDay[day.day()] === "qua",
        "last-qui": day.lastDay && this.weekDay[day.day()] === "qui",
        "last-sex": day.lastDay && this.weekDay[day.day()] === "sex",
        "last-sab": day.lastDay && this.weekDay[day.day()] === "sab",
        "last-dom": day.lastDay && this.weekDay[day.day()] === "dom",
        seg: day.firstDay && this.weekDay[day.day()] === "seg",
        ter: day.firstDay && this.weekDay[day.day()] === "ter",
        qua: day.firstDay && this.weekDay[day.day()] === "qua",
        qui: day.firstDay && this.weekDay[day.day()] === "qui",
        sex: day.firstDay && this.weekDay[day.day()] === "sex",
        sab: day.firstDay && this.weekDay[day.day()] === "sab",
        dom: day.firstDay && this.weekDay[day.day()] === "dom",
        "active-range": day.activeRangeStart_toggle,
        "active-range -start":
          day.activeRangeStart &&
          !moment(this.selectedStartDate).isSame(this.selectedEndDate),
        "active-range -end":
          day.activeRangeEnd &&
          !moment(this.selectedStartDate).isSame(this.selectedEndDate),
        "active-range -def": day.resetActive,
        "within-active-range": day.withinActiveRange,
        "not-within-active-range": day.isNotWithinActiveRange,
      };
    },
    changeBillingPeriod(change_type: string) {
      this.tempStartDate = null;
      this.tempEndDate = null;
      this.currentPage = 1;
      let index =
        change_type == "next"
          ? this.selected_index + 1
          : this.selected_index - 1;

      if (index < 0) {
        index = 0;
      } else if (
        index >
        this.billing_periods.length - 1
      ) {
        index = this.billing_periods.length - 1;
      }

      let d1 = new Date(
        this.billing_periods[index].start_date
      );
      let d2 = new Date(
        this.billing_periods[index].end_date
      );
      let st = moment(d1);
      let ed = moment(d2);
      this.changeSelectedConsumptionIndex({
        index: index,
      });
      this.billingPeriodStartDate = this.selectedStartDate =
        this.setBillingPeriod("start");
      this.billingPeriodEndDate = this.selectedEndDate =
        this.setBillingPeriod("end");
    },
    resetToBillingPeriod() {
      this.selectedStartDate = this.billingPeriodStartDate;
      this.selectedEndDate = this.billingPeriodEndDate;
      this.tempStartDate = null;
      this.tempEndDate = null;
    },

    changeCustomDateRange(day: any) {
      if (day.disabled) return;
      const startDayDiff = day.diff(
        moment(this.billingPeriodStartDate),
        "days"
      );
      const endDayDiff = day.diff(moment(this.billingPeriodEndDate), "days");
      if (startDayDiff < 0 || endDayDiff > 0) return;

      if (!this.tempStartDate) {
        this.tempStartDate = day.clone();
        this.tempEndDate = null;
        this.selectedEndDate = null;
        if (
          this.tempStartDate! < this.selectedEndDate ||
          moment(this.tempStartDate).isSame(this.tempEndDate)
        ) {
          this.selectedStartDate = this.tempStartDate!;
          return;
        } else {
          this.selectedStartDate = day.clone();
          this.tempEndDate = null;

          return;
        }
      } else {
        this.tempEndDate = day.clone();
        if (this.tempEndDate! > this.tempStartDate!) {
          this.selectedEndDate = this.tempEndDate;
          this.selectedStartDate = this.tempStartDate;
          this.tempEndDate = null;
          this.tempStartDate = null;
          return;
        }
        this.selectedStartDate = this.tempEndDate;
        this.selectedEndDate = this.tempStartDate;

        this.tempEndDate = null;
        this.tempStartDate = null;
        return;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
$primary_color: #ff5a5f;
$secondary_color: #ff7478;
$white: white;
$black: black;
$black_15: rgba(0, 0, 0, 0.15);

//@extend-elements
%day_styles {
  flex-basis: percentage(1/7);
  flex-grow: 1;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  height: 40px;
  padding: 0;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
}

.overflow-auto {
  max-height: 361px;
  overflow: scroll;
}

.heights {
  height: 280px;
}

.submit {
  width: 166.5px;
  height: 50px;
  border-radius: 5px;
  background-color: #47aea5;
  color: #fff;
  font-weight: bold;
}

.reset {
  width: 166.5px;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #47aea5;
  font-weight: bold;
  color: #47aea5;
}

.date-time {
  font-size: 22px;
}

.day {
  width: 27.8px;
}

// .container-dates {
//   width: 50%;
//   min-width: 401px;
// }

.dot {
  height: 14.5px;
  width: 14.5px;
  border-width: 2px;
  background-color: #fff;
  border-style: solid;

  border-color: #a7a7a7;
  border-radius: 50%;
  display: inline-block;
}

.dot2 {
  height: 14.5px;
  width: 14.5px;
  border-width: 2px;
  background-color: #47aea5;
  border-style: solid;

  border-color: #47aea5;
  border-radius: 50%;
  display: inline-block;
}

.days {
  width: 100%;
  height: 40px;
  background-color: #464646;
  color: #fff;
  font-size: 14px;
}

.days1 {
  width: 50%;
  height: 40px;
  background-color: #464646;
  color: #fff;
  font-size: 14px;
}

.cd-wrapper {
  width: 100%;
  font-family: inherit;
}

.cd-body-wrapper {
  border-radius: 2px;
  width: 100%;
}

@media only screen and (min-width: 389px) and (max-width: 960px) {
  .overflow-auto {
    //max-height: 400px;
  }
}

@media only screen and (max-width: 375px) {
  .blocks {
    height: 45px !important;
    width: 45px !important;
  }
}

.cd-weekdays {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  display: flex;
  flex-wrap: wrap;

  >article {
    @extend %day_styles;
    cursor: default;
  }

  button {
    @extend %day_styles;
  }
}

header {
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1em;
  text-align: center;
  position: relative;

  >button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    margin: 0;
    height: 25px;
    width: 25px;

    &:first-child {
      left: 25px;
    }

    &:last-child {
      right: 25px;
    }
  }

  >article {
    >span:first-child {
      font-weight: bold;
    }
  }
}

.cd-days {
  padding: 5px -1px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8em;

  >article {
    @extend %day_styles;
  }

  button {
    @extend %day_styles;
  }

  >button {
    &.not-within-active-range {
      color: #c7c7c7;
    }

    >span {
      position: relative;
      z-index: 10;
    }

    >figure {
      display: block;
      margin: auto;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      opacity: 0;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    //Check if this is ok, couldnt figure out doing this with vuetify
    &.last-seg {
      margin-right: 71.4%;
    }

    &.last-ter {
      margin-right: 57.1%;
    }

    &.last-qua {
      margin-right: 42.8%;
    }

    &.last-qui {
      margin-right: 28.5%;
    }

    &.last-sex {
      margin-right: 14.2%;
    }

    &.last-sab {
      margin-right: 0;
    }

    &.last-dom {
      margin-right: 85.7%;
    }

    &.seg {
      margin-left: 14.2%;
    }

    &.ter {
      margin-left: 28.5%;
    }

    &.qua {
      margin-left: 42.8%;
    }

    &.qui {
      margin-left: 57.1%;
    }

    &.sex {
      margin-left: 71.4%;
    }

    &.sab {
      margin-left: 85.7%;
    }

    &.dom {
      margin-left: 0;
    }

    &.within-active-range:not(.active-range) {
      >button {}

      >figure {
        height: 40px;
        opacity: 0.2;
        transform: translate3d(-50%, -50%, 0) scale(1);
        border-radius: 0px;
        padding: 0px;
        width: 100%;
      }
    }

    &.active-range {
      >figure {
        width: 40px;
        height: 40px;
      }

      &.-start {
        background: linear-gradient(90deg,
            #fff 50%,
            rgba(59, 187, 200, 0.2) 50%);
      }

      &.-end {
        background-color: rgba(59, 187, 200, 0.2);
        background: linear-gradient(90deg,
            rgba(59, 187, 200, 0.2) 50%,
            #fff 50%);
        height: 40px;

        >figure {
          height: 40px;
        }
      }
    }

    &.active,
    &.active-range {
      color: $white;

      >figure {
        opacity: 1 !important;
      }

      &.-def {
        >span {
          color: #47aea5 !important;
        }

        >figure {
          background-color: #fff !important;
          border: solid 1px #47aea5 !important;
        }
      }
    }

    &:hover:not(.active):not(.disabled) {
      >button {
        transform: translate3d(-50%, -50%, 0) scale(1);
        opacity: 0.2;
      }
    }
  }
}

.container-dates {
  //max-height: 430px;
}

.blocks {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.blocks-enabled {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: $blue-1;
}

.chevron::before {
  color: #919191;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  left: 0;
  position: relative;
  top: 0;
  vertical-align: top;


}

.chevron-enabled::before {
  color: #fff;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  left: 0;
  position: relative;
  top: 0;
  vertical-align: top;
}

.right::before {
  transform: rotate(45deg);
}

.left::before {
  transform: rotate(-135deg);
}

.chevron .chevron-enabled {
  &.right:before {
    transform: rotate(-45deg);
  }

  &.bottom:before {
    transform: rotate(35deg);
  }

  &.left:before {
    transform: rotate(-135deg);
  }
}

button {
  box-sizing: content-box;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;

  &:active {
    outline: none;

    >figure:after {
      animation: pulse 500ms ease-in-out forwards;
    }
  }

  &:focus {
    outline: none;

    >figure:after {
      animation: pulse 500ms ease-in-out forwards;
    }
  }

  >figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    &:after {
      background: $black_15;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      visibility: hidden;
    }
  }
}

figure {
  margin: 0;
}

footer {
  button {
    position: relative;
    cursor: pointer;
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;

    .st0 {
      fill: none;
      stroke: $black;
      stroke-width: 35;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }

  svg {
    width: 50px;
    height: 50px;

    .st0 {
      fill: none;
      stroke: $black;
      stroke-width: 35;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }
}

* {
  &.animate-next {
    animation: monthChange 400ms cubic-bezier(0.66, -0.01, 0.69, 1.23) normal forwards;
  }

  &.animate-prev {
    animation: monthChange 400ms cubic-bezier(0.66, -0.01, 0.69, 1.23) reverse forwards;
  }
}

@keyframes pulse {
  0% {
    visibility: visible;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    visibility: visible;
    opacity: 0;
    transform: scale3d(1.4, 1.4, 1);
  }
}

@keyframes monthChange {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  40% {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
  }

  60% {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>

