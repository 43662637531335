<template>
  <v-row align="center" class="text text-5 footer-text mb-0">
    <v-col cols="12" class="d-flex justify-center mt-2">
      <img class="login-logo" height="43" src="@/assets/logos/sel-logo.svg" />
    </v-col>
    <v-col cols="12" class="text-center">
      © 2024
      <a href="https://www.smartenergylab.pt" class="link" target="_blank"
        >Smart Energy Lab</a
      >. <br />Todos os Direitos Reservados.
    </v-col>
    <!-- <v-col cols="12" class="text-center mb-5">
      Suporte:
      <a href="mailto:edge@smartenergylab.pt" class="link"
        >edge@smartenergylab.pt</a
      >
    </v-col> -->
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "FooterText",
  props: ["activeTab"],
});
</script>

<style lang="scss" scoped>
.footer-text {
  color: $white-1;
}

.link {
  color: $blue-1;
}
</style>
