<template>
  <v-row class="pb-4">
    <v-col cols="12" class="text-left header header-3 pt-0 pb-0">
      <span v-if="this.selected_index == 0">Consumo do Período Atual</span>
      <span v-if="this.selected_index != 0">Consumos Totais do Período</span>
    </v-col>
    <v-col
      v-if="
        this.selected_index == 0 &&
        this.consumption_data.updated_at &&
        this.consumption_data
      "
      cols="12"
      class="text-left text text-normal pt-1 pb-0"
    >
      <strong>Atualizado a:</strong>
      {{
        transformDateDDMMYYtoDDMMYYYY(
          this.consumption_data.updated_at.date.replaceAll("/", "-")
        )
      }}
      às
      {{ this.consumption_data.updated_at.time }}
    </v-col>
    <v-col class="mt-3" cols="12">
      <bill-period-selector :consumo="true" />
    </v-col>
    <v-col class="pt-0">
      <v-card class="col-12 rounded-lg">
        <v-row>
          <!-- <v-col
            cols="12"
            class="header header-4 bottom-liner"
            v-if="selected_consumption.billing_period"
          >
            {{ getTransMonth(selected_consumption.billing_period.start_date) }}
            {{ getYear(selected_consumption.billing_period.start_date) }}
          </v-col> -->
          <v-col cols="12" class="py-3 text">
            <v-row>
              <!-- Consumption energy -->
              <v-col class="right-liner my-3">
                <actual-consumption-stat
                  :icon="'icon-thunder.png'"
                  :stat_type="'Consumos Totais'"
                  :energy="true"
                  :stat_value="
                    this.consumption_data.totals
                      ? this.consumption_data.totals.total_energy
                      : 0
                  "
                />
              </v-col>
              <!-- Consumption cost -->
              <v-col class="my-3">
                <actual-consumption-stat
                  :icon="'icon-euro.png'"
                  :stat_type="'A Receber'"
                  :stat_value="`${
                    this.consumption_data.totals
                      ? this.consumption_data.totals.total_cost
                      : 0
                  } €`"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="text text-small top-liner d-flex"
            v-if="this.billing_periods"
          >
            <span><strong>Período: </strong></span>&nbsp;
            <div
              class="d-flex"
              v-if="
                this.s_date &&
                this.profiles.length != 0 &&
                this.billing_periods.length != 0
              "
            >
              {{ this.start_date_bp() }}
              &nbsp;a&nbsp;
              {{ this.end_date_bp() }}
            </div>
            <div v-if="!this.e_date">Por definir.</div>
          </v-col>
          <v-col
            cols="12"
            class="text"
            v-if="
              this.consumption_data.consumptions
                ? this.consumption_data.consumptions.length != 0
                : false
            "
          >
            <!-- Charging cost table -->
            <charging-cost-table :users="this.consumption_data.consumptions" />
          </v-col>
          <v-col
            cols="12"
            class="text"
            v-if="
              !this.consumption_data.consumptions ||
              this.consumption_data.consumptions.length == 0
            "
          >
            <!-- Charging cost table no data-->
            <charging-cost-table-no-data />
          </v-col>
        </v-row>
      </v-card>
      <!-- <v-row v-if="consumption_data.latest" class="pt-3">
        <v-col style="font-size: 12px">
          Os custos apresentados neste quadro são estimativas.
        </v-col>
      </v-row> -->
      <v-row class="pt-3">
        <v-col style="font-size: 12px">
          <span v-if="this.consumption_data.confirmed == false">
            <router-link
              @click="handleChangeTab(3)"
              :to="{ path: '/dashboard/support', query: { cat: 1 } }"
            >
              <b style="color: black">custos estimados</b> </router-link
            >, incluindo IVA à taxa legal em vigor. </span
          ><span v-if="this.consumption_data.confirmed == true"
            >Incluindo IVA à taxa legal em vigor.</span
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import ActualConsumptionStat from "@/components/dashboard/actual-consumption-stat.vue";
import ChargingCostTable from "@/components/dashboard/charging-cost-table.vue";
import ChargingCostTableNoData from "./charging-cost-table-no-data.vue";
import { General } from "@/mixins/general";
import BillPeriodSelector from "./bill-period-selector.vue";
import moment from "moment";

export default mixins(General).extend({
  name: "ActualConsumptionManager",
  props: ["cost", "energy", "start_date", "end_date", "updated_at", "users"],
  data() {
    return {
      data: new Date(),
    };
  },

  methods: {
    handleChangeTab(val: any) {
      this.$store.commit("updateState", { active_tab: val });
    },

    start_date_bp() {
      const start_date = moment(this.s_date.send_format).format("DD-MM-YYYY");
      return start_date;
    },

    end_date_bp() {
      const end_date = moment(this.e_date.send_format).format("DD-MM-YYYY");
      return end_date;
    },
  },

  components: {
    ActualConsumptionStat,
    ChargingCostTableNoData,
    ChargingCostTable,
    BillPeriodSelector,
  },
});
</script>
