<template>
  <v-row class="mx-0">
    <v-col
      cols="12"
      style="font-size: 16px"
      class="font-weight-medium px-0 pb-0"
      >Defina a data de início e de fim do último período de faturação</v-col
    >
    <v-col cols="6" class="px-0 pb-0">Data de Início</v-col>
    <v-col cols="6" class="px-0 pb-0 pl-2">Data de Fim</v-col>
    <v-col cols="6" class="px-0 pb-0 pt-1 pr-2">
      <v-text-field
        readonly
        color="#c9c9c9"
        outlined
        placeholder="dd-mm-yyyy"
        dense
        background-color="#ffffff"
        item-color="#000000"
        class="text text-5 text-prefix text-selected"
      >
        <template v-slot:append>
          <span>
            <v-icon color="#a7a7a7" class="send-email-icon">
              mdi-calendar-blank
            </v-icon>
          </span>
        </template>
      </v-text-field></v-col
    >
    <v-col cols="6" class="px-0 pb-0 pt-1 pl-2">
      <v-text-field
        readonly
        color="#c9c9c9"
        outlined
        placeholder="dd-mm-yyyy"
        dense
        background-color="#ffffff"
        item-color="#000000"
        class="text text-5 text-prefix text-selected"
      >
        <template v-slot:append>
          <span>
            <v-icon color="#a7a7a7" class="send-email-icon">
              mdi-calendar-blank
            </v-icon>
          </span>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>



<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "PeriodRange",

  data() {
    return {};
  },

  methods: {},
});
</script>

<style lang="scss" scoped>
.v-input {
  max-height: 54px;
}
</style>
