<template>
  <v-row class="pb-4">
    <v-col cols="12" class="text-left header header-3 pt-0 pb-0">
      Consumos do Período Anterior
    </v-col>
    <v-col
      cols="12"
      class="text-left text text-normal pt-1 pb-0"
      v-if="selected_index == 0 && this.billing_periods[selected_index + 1]"
    >
      De
      {{ this.start_date_bp() }}
      a
      {{ this.end_date_bp() }}
    </v-col>
    <v-col class="pt-2">
      <v-card class="col-12 rounded-lg">
        <v-row>
          <v-col cols="12" class="text">
            <v-row>
              <!-- Consumption sesssion -->
              <v-col class="text-center">
                <period-before-consumption-stat
                  :stat_type="'Sessões'"
                  :stat_value="
                    this.consumption_data_prev.consumptions.length > 0
                      ? this.consumption_data_prev.consumptions[0].sessions
                          .length
                      : 0
                  "
                />
              </v-col>
              <!-- Consumption energy -->
              <v-col class="text-center left-liner right-liner">
                <period-before-consumption-stat
                  :stat_type="'Consumo Total'"
                  :energy="true"
                  :stat_value="
                    this.consumption_data_prev.consumptions.length > 0
                      ? this.consumption_data_prev.consumptions[0].totals
                          .total_energy
                      : 0
                  "
                />
              </v-col>
              <!-- Consumption cost -->
              <v-col v-if="this.consumption_data_prev.consumptions.length > 0">
                <period-before-consumption-stat
                  :stat_type="'Custo Total'"
                  :stat_value="`${
                    this.consumption_data_prev.consumptions.length > 0
                      ? this.consumption_data_prev.consumptions[0].totals
                          .total_cost
                      : 0
                  } €`"
                />
              </v-col>
              <v-col
                cols="12"
                class="text"
                v-if="
                  this.selected_index == 0 &&
                  !this.consumption_data_prev.consumptions[0]
                "
              >
                <!-- Charging cost table no data -->
                <charging-cost-table-no-data />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="pt-3">
        <v-col
          style="font-size: 12px"
          v-if="this.consumption_data_prev.consumptions"
        >
          <router-link
            @click="handleChangeTab(3)"
            :to="{ path: '/dashboard/support', query: { cat: 1 } }"
          >
            <span
              v-if="this.consumption_data_prev.confirmed == false"
              class="font-weight-bold"
              style="color: #cc8e67"
              >custo estimado,
            </span></router-link
          ><span
            v-if="this.consumption_data_prev.confirmed == true"
            class="font-weight-bold"
            >custo confirmado,
          </span>
          <span>incluindo IVA taxa legal em vigor</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import PeriodBeforeConsumptionStat from "@/components/dashboard/period-before-consumption-stat.vue";
import ChargingCostTableNoData from "@/components/dashboard/charging-cost-table-no-data.vue";
import { General } from "@/mixins/general";
import moment from "moment";

export default mixins(General).extend({
  name: "PeriodBeforeConsumption",
  props: ["cost", "energy", "start_date", "end_date", "sessions_count"],
  components: {
    PeriodBeforeConsumptionStat,
    ChargingCostTableNoData,
  },
  methods: {
    start_date_bp() {
      return moment(
        this.billing_periods[this.selected_index + 1].start_date
      ).format("DD-MM-YYYY");
    },
    end_date_bp() {
      return moment(
        this.billing_periods[this.selected_index + 1].end_date
      ).format("DD-MM-YYYY");
    },
  },
});
</script>
