<template>
  <v-row>
    <v-col cols="12" class="text table-header-area table-header-area-radius">
      <v-row class="text text-5 table-header">
        <v-col class="col-dia">Dia e hora</v-col>
        <v-col class="col-others d-flex justify-center">Tempo</v-col>
        <v-col class="col-others d-flex justify-center">kWh</v-col>
        <v-col class="col-others d-flex justify-center">A Pagar</v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row
        class="text text-5"
        :key="index"
        v-for="(value, index) in sessions"
      >
        <v-col class="col-dia">
          {{ start_date_bp(value.start_time.date) }}
          {{ value.start_time.time }}</v-col
        >
        <v-col
          class="left-liner right-liner col-others d-flex justify-center"
          >{{ value.duration.replace("m", "min") }}</v-col
        >
        <v-col class="right-liner col-others d-flex justify-center">{{
          value.costs.total_energy
        }}</v-col>
        <v-col class="right-liner col-others d-flex justify-center"
          >{{ value.costs.total_cost }}€</v-col
        >
      </v-row>
    </v-col>
    <v-col cols="12" class="top-liner d-flex">
      <v-row class="px-0 py-0 d-flex">
        <v-col
          style="color: #a7a7a7"
          class="justify-start d-flex align-center py-0"
          >{{ start + 1 }}-{{
            this.consumption_data.consumptions[0].sessions.length > counter + 5
              ? counter + 5
              : this.consumption_data.consumptions[0].sessions.length
          }}
          / {{ this.consumption_data.consumptions[0].sessions.length }}</v-col
        >
        <v-col class="d-flex justify-end py-0">
          <button :disabled="!this.enable_prev" @click="prev(-1)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5109 16.4L5.88867 9.99996L12.5109 3.59996C12.8868 3.2379 13.485 3.24909 13.847 3.62496C14.2091 4.00082 14.1979 4.59901 13.822 4.96107L8.60534 9.99996L13.822 15.0444C14.1979 15.4065 14.2091 16.0047 13.847 16.3805C13.485 16.7564 12.8868 16.7676 12.5109 16.4055L12.5109 16.4Z"
                :fill="!this.enable_prev ? '#D8D8D8' : '#2E3335'"
              />
            </svg>
          </button>
          <div class="d-inline" style="margin: 10px; padding-bottom: 5px">
            <span>{{ pageNumber }}</span>
          </div>
          <button :disabled="!this.enable_next" @click="next(1)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.48911 16.4L14.1113 9.99996L7.48911 3.59996C7.11325 3.2379 6.51505 3.24909 6.15299 3.62496C5.79094 4.00082 5.80213 4.59901 6.17799 4.96107L11.3947 9.99996L6.17799 15.0444C5.80213 15.4065 5.79094 16.0047 6.15299 16.3805C6.51505 16.7564 7.11324 16.7676 7.48911 16.4055L7.48911 16.4Z"
                :fill="!this.enable_next ? '#D8D8D8' : '#2E3335'"
              />
            </svg>
          </button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import moment from "moment";

export default mixins(General).extend({
  name: "ChargingSessionTable",
  data() {
    return {
      pageNumber: 1 as any,
    };
  },
  computed: {
    sessions() {
      return this.consumption_data.consumptions[0].sessions.slice(
        this.counter,
        this.counter + 5
      );
    },
    enable_next(): boolean {
      return (
        this.consumption_data.consumptions[0].sessions.length > this.counter + 5
      );
    },

    enable_prev(): boolean {
      return this.pageNumber > 1;
    },
  },

  methods: {
    setPage(page: number) {
      this.counter += page * 5;
      this.pageNumber += page;
    },
    start_date_bp(date: string) {
      const date_ = moment(date, "DD-MM-YY");
      const formattedDate = date_.format("DD-MM-YYYY");
      return formattedDate;
    },

    next(page: number) {
      this.pageNumber += page;
      let n_start = this.start;
      let n_counter = this.counter;
      if (this.consumption_data.consumptions[0].sessions.length > n_counter) {
        n_start = n_start + this.length;
      }
      if (n_start > this.consumption_data.consumptions[0].sessions.length) {
        n_counter = this.consumption_data.consumptions[0].sessions.length;
      } else {
        n_counter = n_start;
      }
      this.checkButton(n_start, n_counter);
    },
    prev(page: number) {
      this.pageNumber += page;
      let n_start = this.start;
      let n_counter = this.counter;
      n_start = n_start - this.length;
      if (n_start <= 0) {
        n_start = 0;
        n_counter = 0;
      } else {
        n_counter = n_start;
      }
      this.checkButton(n_start, n_counter);
    },
  },
});
</script>
<style scoped lang="scss">
.col-dia {
  flex: 0 0 30%;
  max-width: 30%;
}

.col-others {
  flex: 0 0 23.3333333%;
  max-width: 23.3333333%;
}
</style>
