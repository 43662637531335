<template>
  <div>
    <v-snackbar
      v-model="cookieState"
      :dark="false"
      text=""
      timeout="-1"
      width="100%"
      max-width="100%"
      height="fit-content"
    >
      <div class="d-flex justify-center align-center bar-content">
        <div style="width: 100%" class="col-2 custom-div">
          <img
            style="width: 90px; min-width: 90px"
            src="@/assets/logos/edge-logo.svg"
          />
        </div>
        <div class="col-7 pl-0 cookie-text">
          <p class="strong-text">Este Website usa Cookies</p>
          <p>
            Estamos a usar cookies analíticos e de desempenho para podermos 
            melhorar os serviços e o funcionamento deste website. 
            Clique em aceitar caso autorize o uso destes cookies.
          </p>
        </div>
        <div class="col-3 custom-div">
          <v-btn
            :buttonSize="$vuetify.breakpoint.sm ? 'fit-content' : '130px'"
            :name="'close_cookie_bar'"
            @click="togglePrivacyCookie(false, true)"
            color="#47aea5"
            class="login-button mr-4"
            rounded-md
            ><div class="button" style="font-size: 16px">Aceitar</div>
          </v-btn>
          <v-btn
            :buttonSize="$vuetify.breakpoint.sm ? 'fit-content' : '130px'"
            :name="'close_cookie_bar'"
            @click="togglePrivacyCookie(false, false)"
            color="#47aea5"
            class="login-button"
            :outlined="true"
            rounded-md
            ><div style="color: #47aea5; font-size: 16px" class="button">
              Rejeitar
            </div></v-btn
          >
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import config from "@/config";
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "CookieSnackbar",

  data() {
    return {};
  },
  computed: {
    cookieState: {
      get(): any {
        return this.$store.getters["privacyCookie"];
      },
    },
  },
  methods: {
    displayPrivacyCookieModal(status: boolean): void {
      this.$store.commit("toggleModalState", {
        name: "cookie",
        status: status,
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.custom-div {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .bar-content {
    flex-direction: column;
    width: 100%;
      display: block;
      flex: unset;
      max-width: unset;
      justify-content: flex-start !important;
      padding: 0;
      margin-bottom: 4px;
      font-size: 12px;

      > p {
        margin-bottom: 0;
      }
    }
  }

  .bar-content .cookie-text {
    margin-bottom: 16px;

    .strong-text {
      margin-top: 5px;
    }
  }


.cookie-text {
  margin-top: 8px;
  margin-bottom: -15px;

  .strong-text {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.cookie-bar {
  background-color: white;
  height: fit-content !important;
  margin: 0 !important;
  z-index: 10 !important;
}

.close-cookie-btn {
}

.cookie-spacing {
  margin-left: 10px;
}
</style>
