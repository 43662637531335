<template>
  <v-row class="px-0">
    <v-col v-if="title" cols="12" :class="['text text-5  pb-0 pt-0', bold ? 'normal-label' : '']">
      {{ title }}
    </v-col>
    <v-col cols="12" class="pt-1 pb-0" v-if="!title_alone && !isDate">
      <v-textarea :placeholder="placeholder" :suffix="suffix" :type="text_field_type" v-model="faq_value"
        :disabled="this.disabled" :rows="5" row-height="30" auto-grow :height="250"
        :background-color="this.disabled == true ? '#DFDFDF' : '#ffffff'" dense outlined :flat="true"
        :class="[field_value ? 'filled-input' : '']" v-on:keyup="callMethod" v-on:change="callMethod"></v-textarea>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "FormText",
  data() {
    return {
      menu1: false,
      date: new Date().toISOString().substring(0, 10),
    };
  },
  props: [
    "title",
    "placeholder",
    "suffix",
    "bold",
    "title_alone",
    "icon",
    "isDate",
    "disabled",
  ],
});
</script>

<style lang="scss"></style>
