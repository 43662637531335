import { render, staticRenderFns } from "./form-radio.vue?vue&type=template&id=420a1c4a&scoped=true"
import script from "./form-radio.vue?vue&type=script&lang=ts"
export * from "./form-radio.vue?vue&type=script&lang=ts"
import style0 from "./form-radio.vue?vue&type=style&index=0&id=420a1c4a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420a1c4a",
  null
  
)

export default component.exports