export default {
  apiUrl: process.env.VUE_APP_API_BASE_URL,
  errorMessages: {
    unknown_error: "Ocorreu um erro desconhecido!",
    no_network: "Erro de conexão de rede!",
    invalid_login: "Detalhes de login inválidos!",
    required: "Campo Obrigatório!",
    invalid_email: "Formato de email inválido!",
    forbidden_access: "Acesso se proibido!",
    form_has_error: "Por favor verifique os erros nos campos do formulário!",
  },
  //Just some object because API still not up
  condosResponse: {
    condos:localStorage.getItem('condos') ? JSON.parse(localStorage.getItem("condos") as any) : [],
    selected_profile: localStorage.getItem('selected_profile') ?localStorage.getItem('selected_profile') : null ,
    selected_box:localStorage.getItem('selected_box') ? localStorage.getItem('selected_box') : null ,
    selected_condo:localStorage.getItem('selected_condo') ? localStorage.getItem('selected_condo') : null,
    selected_role:localStorage.getItem('selected_role') ? localStorage.getItem('selected_role') : null,
}
,
billing_address:{
  address: "",
  city: "",
  name:"",
  country: "",
  postal_code: "",
  door: "",
  floor: "",
  tax_identification_number: "",
  address_type: 'Billing Address',
},
billing_address_dummy:{
  address: "",
  city: "",
  name:"",
  country: "",
  postal_code: "",
  door: "",
  floor: "",
  tax_identification_number: "",
  address_type: 'Billing Address',
},
  faqData: {
    category: null,
    sub_category: null,
    document_upload: [],
    message: null,
    state: null,
  },
  faqs: [
    {
      title: "",
      faq: {
        title: "",
        text: "",
      },
    },
  ],
    editData: [
    {
      bp_start_date: "",
      bp_end_date: "",
      editable_bill: false,
      cycle: null,
      plan: null,
      contracted_power: null,
      schedule_type: "0",
      regulated_market: null,
      tariff_groups: [
        {
          power_price: null,
          t1_energy_price: null,
          t2_energy_price: null,
          t3_energy_price: null,
          t4_energy_price: null,
          t1_grid_price: null,
          t2_grid_price: null,
          t3_grid_price: null,
          t4_grid_price: null,
          mec_ajuste: 0,
          banda_reg: 0,
          tariff_plan: [],
          start_date: "",
          end_date: "",
        },
      
      ],
    },
  ],
  contractData: [
    {
      bp_start_date: "",
      bp_end_date: "",
      editable_bill: false,
      cycle: null,
      plan: null,
      contracted_power: null,
      schedule_type: "0",
      regulated_market: null,
      tariff_groups: [
        {
          power_price: null,
          t1_energy_price: null,
          t2_energy_price: null,
          t3_energy_price: null,
          t4_energy_price: null,
          t1_grid_price: null,
          t2_grid_price: null,
          t3_grid_price: null,
          t4_grid_price: null,
          mec_ajuste: 0,
          banda_reg: 0,
          tariff_plan: [],
          start_date: "",
          end_date: "",
        },
      
      ],
    },
  ],
  tariffInfo: [
    {
      cycle: null,
      plan: null,
      contracted_power: null,
      power_price: null,
      schedule_type: null,
      t1_energy_price: null,
      t2_energy_price: null,
      t3_energy_price: null,
      t4_energy_price: null,
      t1_grid_price: null,
      t2_grid_price: null,
      t3_grid_price: null,
      t4_grid_price: null,
      mec_ajuste: null,
      banda_reg: null,
      regulated_market: false,
      tariff_plan: [],
      start_date: "",
      end_date: "",
    },
  ],
  dateF: {
    display_format: "",
    send_format: "",
  },
};
