<template>
  <v-row class="mt-4 mb-3 text-center">
    <v-col v-if="!this.profile || this.condos_response.selected_role != 'user'" class="pr-0">
      <v-btn depressed class="button tab-inactive button-text-2 tab-round-left" :class="{
        'tab-active': active_tab === 0 && !this.profile,
        'tab-active-profile': active_tab_profile === 0 && this.profile
      }" v-on:click="changeActiveTab(0)" block>
        {{ tab1 }}
      </v-btn>
    </v-col>
    <v-col :class="!(!this.profile || tab1 != null) ? 'pr-0' : 'px-0'">
      <v-btn depressed class="button tab-inactive  button-text-2" :class="{
        'tab-active': active_tab === 1 && !this.profile,
        'tab-round-left': !(!this.profile || tab1 != null),
        'spacing tab-round-none': (!this.profile || tab1 != null),
        'tab-active-profile': active_tab_profile === 1 && this.profile
      }" v-on:click="changeActiveTab(1)" block>
        {{ tab2 }}
      </v-btn>
    </v-col>
    <v-col class="pl-0">
      <v-btn depressed class="button tab-inactive button-text-2 tab-round-right" :class="{
        'tab-active': active_tab === 2 && !this.profile,
        'tab-active-profile': active_tab_profile === 2 && this.profile
      }" v-on:click="changeActiveTab(2)" block>
        {{ tab3 }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "DashboardMode",
  props: ['tab1', 'tab2', 'tab3', 'profile'],
  methods: {
    changeActiveTab(tab_index: number): void {
      if (this.profile == true) {
        this.$store.commit("updateState", { active_tab_profile: tab_index });
      } else
        this.$store.commit("updateState", { active_tab: tab_index });
    },
  },
});
</script>

<style scoped  lang="scss" >
.tab-inactive {
  height: 50px !important;
  background-color: $white-2 !important;

  color: $gray-8;
  width: 100%;
  font-weight: 600;
}

.tab-active {
  padding-top: 5px !important;
  background-color: $white-1 !important;

  border-bottom: 4px solid $blue-1;
  color: $blue-1;
  font-weight: bold;
}

.tab-inactive-profile {
  height: 50px !important;
  background-color: $white-2 !important;

  color: $gray-8;
  width: 100%;
  font-weight: 600;
}

.tab-active-profile {
  padding-top: 5px !important;
  background-color: $white-1 !important;

  border-bottom: 4px solid $blue-1;
  color: $blue-1;
  font-weight: bold;
}

.spacing {
  border-left: 1px solid #dddbdb;
  border-right: 1px solid #dddbdb;
}
</style>