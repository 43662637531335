<template>
  <v-row>
    <v-col class="text-center">
      <div class="dull-label">{{ stat_type }}</div>
      <div class="text text-1 numeric">
        {{ stat_value }}
        <span style="font-size: 18px" v-if="this.energy == true">kWh</span>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "PeriodBeforeConsumptionStat",
  props: ["stat_type", "stat_value", "energy"],
});
</script>
