var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"text-left header header-3 pt-0 pb-0",attrs:{"cols":"12"}},[(this.selected_index == 0)?_c('span',[_vm._v("Consumo do Período Atual")]):_vm._e(),(this.selected_index != 0)?_c('span',[_vm._v("Consumos Totais do Período")]):_vm._e()]),(
      this.selected_index == 0 &&
      this.consumption_data.updated_at &&
      this.consumption_data
    )?_c('v-col',{staticClass:"text-left text text-normal pt-1 pb-0",attrs:{"cols":"12"}},[_c('strong',[_vm._v("Atualizado a:")]),_vm._v(" "+_vm._s(_vm.transformDateDDMMYYtoDDMMYYYY( this.consumption_data.updated_at.date.replaceAll("/", "-") ))+" às "+_vm._s(this.consumption_data.updated_at.time)+" ")]):_vm._e(),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('bill-period-selector',{attrs:{"consumo":true}})],1),_c('v-col',{staticClass:"pt-0"},[_c('v-card',{staticClass:"col-12 rounded-lg"},[_c('v-row',[_c('v-col',{staticClass:"py-3 text",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"right-liner my-3"},[_c('actual-consumption-stat',{attrs:{"icon":'icon-thunder.png',"stat_type":'Consumos Totais',"energy":true,"stat_value":this.consumption_data.totals
                    ? this.consumption_data.totals.total_energy
                    : 0}})],1),_c('v-col',{staticClass:"my-3"},[_c('actual-consumption-stat',{attrs:{"icon":'icon-euro.png',"stat_type":'A Receber',"stat_value":`${
                  this.consumption_data.totals
                    ? this.consumption_data.totals.total_cost
                    : 0
                } €`}})],1)],1)],1),(this.billing_periods)?_c('v-col',{staticClass:"text text-small top-liner d-flex",attrs:{"cols":"12"}},[_c('span',[_c('strong',[_vm._v("Período: ")])]),_vm._v("  "),(
              this.s_date &&
              this.profiles.length != 0 &&
              this.billing_periods.length != 0
            )?_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(this.start_date_bp())+"  a  "+_vm._s(this.end_date_bp())+" ")]):_vm._e(),(!this.e_date)?_c('div',[_vm._v("Por definir.")]):_vm._e()]):_vm._e(),(
            this.consumption_data.consumptions
              ? this.consumption_data.consumptions.length != 0
              : false
          )?_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('charging-cost-table',{attrs:{"users":this.consumption_data.consumptions}})],1):_vm._e(),(
            !this.consumption_data.consumptions ||
            this.consumption_data.consumptions.length == 0
          )?_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('charging-cost-table-no-data')],1):_vm._e()],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticStyle:{"font-size":"12px"}},[(this.consumption_data.confirmed == false)?_c('span',[_c('router-link',{attrs:{"to":{ path: '/dashboard/support', query: { cat: 1 } }},on:{"click":function($event){return _vm.handleChangeTab(3)}}},[_c('b',{staticStyle:{"color":"black"}},[_vm._v("custos estimados")])]),_vm._v(", incluindo IVA à taxa legal em vigor. ")],1):_vm._e(),(this.consumption_data.confirmed == true)?_c('span',[_vm._v("Incluindo IVA à taxa legal em vigor.")]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }