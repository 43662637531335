import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import Login from "@/views/login.vue";
import Dashboard from "@/views/dashboard.vue";
import Support from "@/views/support.vue";
import Consumo from "@/views/consumo.vue";
import ChangePassword from "@/views/change-password.vue";
import Tariff from "@/views/tariff.vue";
import ManageAccount from "@/views/manage-account.vue";
// import NotFound from "@/views/not-found.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
    meta: { requireAuth: true },
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/recover_password/:token",
    name: "Recover",
    component: ChangePassword,
    props: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requireAuth: true },
    children: [     
     {
        path: "/dashboard",
        name: "Consumo",
        component: Consumo,
    },
      {
        path: "/dashboard/support/",
        name: "Support",
        component: Support,
        props: true,
      },
      {
        path: "/dashboard/tariff",
        name: "Tariff",
        component: Tariff,
        meta: { requireAuth: true, role: "manager" },
      },
      {
        path: "/dashboard/consumos",
        name: "Consumos",
        component: Consumo,
      },
      {
        path: "/dashboard/manage-account",
        name: "ManageAccount",
        component: ManageAccount,
      }
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      store.dispatch("logout").then(() => {
        next("/");
      });
    },
  },
  {
    path: "*",
    redirect: "/dashboard",
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const userRole = store.getters.userRole; 

  if (to.matched.some((r) => r.meta.requireAuth && !isAuthenticated)) {
    next({
      name: "Login",
      params: { wantedRoute: to.fullPath },
    });
  } else if (to.matched.some((r) => r.meta.role && r.meta.role !== userRole)) {
    next({ path: "/dashboard" });
  } else {
    next();
  }
});

export default router;
