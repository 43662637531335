<template>
  <v-row class="px-0">
    <v-col
      cols="12"
      :class="['text text-5  pb-0 pt-0', bold ? 'normal-label' : '']"
    >
      {{ title }}
      <span style="color: rgba(46, 51, 53, 0.3); font-weight: 400">
        (Opcional)</span
      >
    </v-col>

    <v-col cols="12" class="pt-1 pb-0">
      <div
        :class="[
          'upload-area',
          dragging ? 'upload-area-dragging' : '',
          this.faq_data.document_upload.length == 0 ? 'align-center' : '',
          this.disabled == false ? 'disabled-bg' : null,
        ]"
        v-on:dragover.prevent
        v-on:drop="handleFileUpload"
        v-on:dragenter="dragEnter"
        v-on:dragleave="dragLeave"
      >
        <div
          class="upload-area-after"
          v-if="this.faq_data.document_upload.length != 0"
        >
          <!-- File name list for -> without Thumbnail -->
          <template>
            <div class="text-14 px-2 pt-2">
              <v-row
                ><v-col>
                  <v-container grid-list-md>
                    <v-row class="mb-3 d-flex">
                      <v-col class="mx-0 py-0 px-0 my-0">
                        <v-row class="mx-0 py-0 px-0 my-0 grid-list-xl">
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="(f, k) in this.names"
                            :key="k"
                            class="px-2 mb-1 py-0 my-0 parent d-flex justify-center"
                          >
                            <v-row
                              class="mx-0 py-0 px-0 my-0 image-container rounded-lg d-flex justify-center"
                            >
                              <v-col
                                cols="2"
                                class="py-0 pl-0 d-flex align-center"
                                ><img class="image-inside" :src="f.type === 'application/pdf' ? require('@/assets/icons/pdf.svg') : f.url"
                              /></v-col>
                              <v-col
                                cols="8"
                                class="d-flex pl-2 font-weight-bold align-center"
                                >{{ names[k].name }}</v-col
                              >
                              <v-col
                                @click="handleDelete(k)"
                                cols="2"
                                class="d-flex justify-end align-center clickable"
                                ><img
                                  style="height: 36px"
                                  src="@/assets/icons/bin.svg"
                              /></v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <div>
                      <!-- <v-row class="upload-area-before text-14">
                        <v-col cols="12" class="mb-2 d-flex justify-center">
                          <span class="upload-area-link pr-1">Clique aqui </span
                          ><span> ou arraste as suas fotografias</span>
                        </v-col>
                      </v-row> -->
                    </div>
                  </v-container>
                </v-col></v-row
              >
            </div>

            <!-- <div class="upload-area-after-info text-14">
                Arraste mais ficheiros ou exclua os existentes
              </div> -->
          </template>

          <div class="input-area">
            <div>
              <v-row
                v-if="this.faq_data.document_upload.length != 0"
                class="upload-area-before text-14 mb-3 d-flex justify-center"
              >
                <span
                  :style="{
                    color: this.disabled == false ? '#9A9A9A' : '#47aea5',
                  }"
                  class="upload-area-link pr-1"
                  >Clique aqui</span
                ><span> ou arraste as suas fotografias ou pdfs</span>
              </v-row>
            </div>
            <input
              :disabled="!disabled"
              type="file"
              @change="handleFileUpload"
              :ref="'fup'"
              accept=".jpg,.jpeg,.png,.pdf"
            />
          </div>
        </div>

        <div
          class="upload-area-msg mt-9"
          :style="
            this.faq_data.document_upload.length != 0 ? 'top: 127px' : null
          "
          @drag="handleFileUpload"
        >
          <!-- File upload area for -> without Thumbnail -->
          <div v-if="this.faq_data.document_upload.length == 0">
            <img
              :style="{
                filter:
                  this.disabled == false
                    ? 'invert(57%) sepia(46%) saturate(9%) hue-rotate(95deg) brightness(99%) contrast(87%)'
                    : '',
              }"
              src="@/assets/icons/Layer_1.svg"
            />
            <input
              :disabled="!disabled"
              class="clickable"
              type="file"
              @change="handleFileUpload"
              :ref="'fup'"
              accept=".jpg,.jpeg,.png,.pdf"
            />
          </div>
          <!-- End file upload area for -> without Thumbnail -->

          <!-- End name file list for -> without Thumbnail -->

          <!-- Show if no file is uploaded -->
          <v-row
            v-if="this.faq_data.document_upload.length == 0"
            class="clickable upload-area-before text-14 pt-3"
          >
            <span
              :style="{ color: this.disabled == false ? '#9A9A9A' : '#47aea5' }"
              class="upload-area-link"
              >Clique aqui</span
            ><span
              :style="{ color: this.disabled == false ? '#9A9A9A' : 'black' }"
            >
              ou arraste as suas fotografias ou pdfs</span
            >
          </v-row>
          <!-- End show when file list is empty -->

          <!-- End show if on validation required other error will show on snackbar -->
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
export default mixins(General).extend({
  name: "FormPhotoUpload",
  data() {
    return {
      dragging: false,
      names: [] as any,
    };
  },
  methods: {
    dragEnter(e: any) {
      e.preventDefault();
    },

    dragLeave(e: any) {
      e.preventDefault();
    },
    handleDelete(k: any) {
      this.$store.commit("deleteFoto", { id: k });
      this.names.splice(k, 1);
    },


    handleFileUpload(e: any) {
      e.preventDefault();
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

      if (!files.length) return;

      const file = files[0];
      const fileName = file.name;
      const fileType = file.type;

      if (!['image/jpeg', 'image/png' ,'image/jpg','application/pdf'].includes(fileType)) {
        this.$store.dispatch("toggleSnackbar", {
          show: true,
          color: "red", 
          timeout: 5000,
          message:'Por favor, insira um ficheiro do tipo PNG, JPEG, JPG ou PDF.',
        })
      }else{
        const reader = new FileReader();
        reader.onload = (e) => {
          this.names.push({ name: fileName, url: e.target?.result , type: fileType});
          this.$store.dispatch("uploadFile", [...files]);
        };

        reader.readAsDataURL(file);
      }
    }
  },
  props: [
    "title",
    "disabled",
    "placeholder",
    "suffix",
    "bold",
    "title_alone",
    "icon",
    "isDate",
    "opt",
  ],
});
</script>
<style scoped lang="scss">
.custom-label {
  margin-top: 15px;
}

.image-container {
  background-color: #ebebeb;
  width: 98%;
}

.image-inside {
  height: 50px;
  width: 50px;
}

.parent {
  width: 100%;
}

.upload-area {
  min-height: 180px;
  position: relative;
  background-color: white;
  border: 2px dashed #b5b5b5;
  border-radius: 5px;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 10px;
}

.has-error {
  border: 2px dashed #ff5252;
}

.upload-area:hover {
  border: 2px dashed #47aea5;
}

.disabled-bg {
  background-color: #dfdfdf;
}

.upload-area-dragging {
  background: #707070;
  opacity: 0.7;
}

.upload-area-msg {
  color: #273043;
  position: absolute;
  width: 100%;
  text-align: center;
}

.area-message-filled {
  top: 136px;
}

.upload-area-photo {
  width: 60px;
  height: 60px;
  margin: auto;
  background-color: #47aea5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-error-photo {
  background-color: #ff5252;
}

@media screen and (min-width: 320px) {
  .upload-area-photo,
  .upload-area-secondary {
    width: calc(25px + (50-25) * ((100vw - 320px) / (1920-320))) !important;
    height: calc(25px + (50-25) * ((100vw - 320px) / (1920-320))) !important;
  }
}

.upload-area-camera-icon {
  width: 50%;
}

.upload-area-before {
  display: block;

  margin-top: 37px;
  color: #273043;
}

.input-area {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.upload-area-after {
  margin-top: 10px;
  width: 100%;
  color: #273043;

  &.flexible-area {
    overflow-x: auto;
  }
}

.upload-area-link {
  color: #47aea5;
  text-decoration: underline;
}

.upload-area input {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 86px;
}

// .upload-area-after-file {
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &.image-display {
//     position: relative;
//     margin-right: 12px;

//     img {
//       border-radius: 2px;
//     }

//     .icons-1 {
//       position: absolute;
//       top: -10px;
//       left: 86%;
//       z-index: 3;
//     }
//   }
// }

.upload-area-after-info {
  margin-top: 5px;
  color: #47aea5;
}

.small-info-text {
  max-width: 300px;
}

.has-error-text {
  margin-top: 10px;
  color: #ff5252;
}
</style>
