<template>
  <v-dialog
    v-model="modalShow"
    v-if="this.historic_data[index]"
    max-width="570px"
    content-class="simple-content-modal overflow-x-hidden rounded-lg external mx-5 mb-6"
  >
    <v-card class="d-flex flex-column align-center">
      <div class="top-nav d-flex bottom-liner py-0 mb-3">
        <p class="select-period my-4 mx-5 d-flex align-center font-weight-bold">
          {{ this.header }}
        </p>

        <v-icon
          class="ml-auto align-center mr-4 close-modal"
          @click="closeModals(modalName)"
          >mdi-close
        </v-icon>
      </div>

      <v-row style="width: 100%" class="text text-5 table-header-area">
        <v-col class="table-header pl-5" cols="12">
          De {{ convertDateFormat2(this.historic_data[index].bp_start_date) }}

          a {{ convertDateFormat2(this.historic_data[index].bp_end_date) }}
        </v-col>
      </v-row>

      <v-row class="d-flex overflow-x-hidden pl-2" style="width: 100%">
        <v-col cols="12" class="px-0 py-0 pt-3" v-if="this.historic == true">
          <v-row class="px-3 py-0">
            <v-col cols="12" class="tariff-q pb-1"
              >Ciclo temporal da tarifa</v-col
            >

            <v-col cols="12" class="pt-0 pb-0">{{
              this.historic_data[index].cycle == 0 ? "Diário" : "Semanal"
            }}</v-col>

            <v-col cols="12" class="tariff-q pb-1"
              >Nível de tensão da eletricidade do condomínio</v-col
            >

            <v-col cols="12" class="pt-0">{{
              this.tariffText[this.historic_data[index].plan]
            }}</v-col>
          </v-row>
        </v-col>

        <!-- Section Highlighting tariff information-->
        <v-row
          style="width: 100%"
          class="d-flex px-3 py-0"
          v-for="n in this.historic_data[index].tariff_groups.length"
          :key="n"
        >
          <v-col
            class="mr-auto d-flex align-center header-3 pl-3 px-0 font-weight-bold"
            >Sub-Período {{ n }}
          </v-col>
          <v-col class="d-flex justify-end pl-0 pr-3"
            ><v-icon
              size="40"
              @click="toggleRotation(n, true)"
              color="black"
              :class="isRotated && n == catSelected ? 'rotated' : 'revert'"
              >mdi-chevron-right
            </v-icon></v-col
          >
          <v-col cols="12" class="px-0 py-0">
            <v-row class="px-3" v-if="catSelected == n && isRotated">
              <v-col cols="12" class="pt-3 pb-0">
                De
                {{
                  rightFormat(past_data[index].tariff_groups[n - 1].start_date)
                }}
                a
                {{
                  rightFormat(past_data[index].tariff_groups[n - 1].end_date)
                }}
              </v-col>
              <v-col
                cols="12"
                v-if="past_data[index].plan != 0"
                class="tariff-q pb-1"
                >Custo da potência em horas de ponta</v-col
              >

              <v-col
                cols="12"
                v-if="past_data[index].plan != 0"
                class="pt-0 pb-0"
                >{{ past_data[index].tariff_groups[n - 1].power_price }}

                € / kVA</v-col
              >
              <v-col cols="12" class="tariff-q pb-0"
                >Valor das tarifas de energia contratada</v-col
              >

              <v-col
                v-if="past_data[index].tariff_groups[n - 1].tariff_plan[0]"
                cols="12"
                class="pb-0 pt-1"
                ><span class="font-weight-bold"
                  >{{
                    past_data[index].tariff_groups[n - 1].tariff_plan[0].text
                  }}: </span
                >{{
                  past_data[index].tariff_groups[n - 1].tariff_plan[0].energy
                }}

                € / kWh</v-col
              >
              <v-col
                v-if="past_data[index].tariff_groups[n - 1].tariff_plan[1]"
                cols="12"
                class="pt-1 pb-0"
              >
                <span class="font-weight-bold"
                  >{{
                    past_data[index].tariff_groups[n - 1].tariff_plan[1].text
                  }}: </span
                >{{
                  past_data[index].tariff_groups[n - 1].tariff_plan[1].energy
                }}

                € / kWh
              </v-col>

              <v-col
                v-if="past_data[index].tariff_groups[n - 1].tariff_plan[2]"
                cols="12"
                class="pb-0 pt-1"
                ><span class="font-weight-bold"
                  >{{
                    past_data[index].tariff_groups[n - 1].tariff_plan[2].text
                  }}: </span
                >{{
                  past_data[index].tariff_groups[n - 1].tariff_plan[2].energy
                }}

                € / kWh</v-col
              >

              <v-col
                v-if="past_data[index].tariff_groups[n - 1].tariff_plan[3]"
                cols="12"
                class="pt-1 pb-0 mb-4"
                ><span class="font-weight-bold"
                  >{{
                    past_data[index].tariff_groups[n - 1].tariff_plan[3].text
                  }}: </span
                >{{
                  past_data[index].tariff_groups[n - 1].tariff_plan[3].energy
                }}

                € / kWh</v-col
              >
              <v-col
                cols="12"
                class="px-3 py-0"
              >
                <v-row class="px-0 py-0">
                  <v-col cols="12" class="tariff-q pb-0"
                    >Valor das tarifas de Redes</v-col
                  >
                  <v-col cols="12" class="pb-0 pt-1" v-for="teste in past_data[index].tariff_groups[n - 1].tariff_plan" :key="teste">
                    <span >
                      <span class="font-weight-bold">
                        {{ teste.text}}: 
                      </span>
                        {{ teste.grid }} € / kWh
                    </span>
                    <!-- <span class="font-weight-bold" v-else>
                      Não existe Tarifas de redes
                    </span> -->

                  </v-col>
                </v-row>
              </v-col>
              <div
                v-if="
                  past_data[index].tariff_groups[n - 1].mec_ajuste != null &&
                  past_data[index].tariff_groups[n - 1].mec_ajuste != 0
                "
              >
                <v-col cols="12" class="tariff-q pb-0"
                  >Valor de "Mecanismo de Ajuste"</v-col
                >

                <v-col cols="12" class="pt-1"
                  >{{ past_data[index].tariff_groups[n - 1].mec_ajuste }}

                  € / kVA</v-col
                >
              </div>

              <div
                v-if="
                  past_data[index].tariff_groups[n - 1].banda_reg != null &&
                  past_data[index].tariff_groups[n - 1].banda_reg != 0
                "
              >
                <v-col cols="12" class="tariff-q pt-0 pb-0"
                  >Valor de "Banda de Reserva de Regulação"</v-col
                >

                <v-col cols="12" class="pt-1"
                  >{{ past_data[index].tariff_groups[n - 1].banda_reg }}

                  € / kVA</v-col
                >
              </div>

              <v-col cols="12" class="pt-1">
                <span
                  style="
                    color: #343a3d;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                  "
                  >Valor sem IVA incluído</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" class="pb-5 pr-5 pt-8">
          <submit-button
            :title="'Sair'"
            @callEvent="closeModals(modalName)"
            :name="'send-manager-report'"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { General } from "@/mixins/general";

import mixins from "vue-typed-mixins";
import moment from "moment";
import submitButton from "../dashboard/submit-button.vue";

export default mixins(General).extend({
  components: { submitButton },

  name: "tariffModal",

  props: ["modalName", "historic", "header", "index"],

  data() {
    return {
      isRotated: false,
      catSelected: 0,
      togglePeriod: 0,

      tariffText: [
        "Baixa Tensão Normal (BTN)",

        "Baixa Tensão Especial (BTE)",

        "Média Tensão",

        "Alta Tensão (AT)",
      ],
    };
  },

  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },

      set() {
        this.togglePeriod = 0;

        this.closeModal(this.modalName);
      },
    },

    past_data(): any {
      return this.historic_data;
    },
  },

  methods: {
    toggleRotation(index: any, status: boolean): any {
      this.$store.commit("updatePeriod", index - 1);
      //For if the previous state is rotated and we select another we need to be able to close that one
      if (this.isRotated == true && this.catSelected == index) {
        this.isRotated = false;
      } else {
        this.catSelected = index;
        this.isRotated = status;
      }
    },
    rightFormat(date: string) {
      return moment(date).format("DD-MM-YYYY");
    },
    closeModals(modalName: any) {
      this.togglePeriod = 0;
      this.closeModal(modalName);
    },
    changeActiveTab(period: any): void {
      this.togglePeriod = period;
    },
  },
});
</script>




<style lang="scss" scoped>
.simple-content-modal {
  position: relative;

  min-width: 350px;

  background-color: rgba(107, 107, 107, 0.293);
}

.top-nav {
  width: 100%;

  height: 40px;

  font-size: 18px;
}

.tariff-q {
  color: #47aea5;

  font-weight: bold;
}

.tarif-button {
  width: 120px;

  height: 50px;

  background-color: #47aea5;

  color: white;
}

.tab-inactive {
  height: 50px !important;

  background-color: $white-2 !important;

  color: $gray-8;

  width: 100%;

  font-weight: 600;
}

@keyframes rotate90 {
  to {
    transform: rotate(90deg);
  }
}

@keyframes Nrotate90 {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.disabled-text {
  color: #b1b1b1 !important;
}

.revert {
  animation-name: Nrotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.rotated {
  animation-name: rotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.tab-active {
  padding-top: 5px !important;

  background-color: $white-1 !important;

  border-bottom: 4px solid $blue-1;

  color: $blue-1;

  font-weight: bold;
}

.spacing {
  border-left: 1px solid #dddbdb;

  border-right: 1px solid #dddbdb;
}
</style>