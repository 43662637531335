<template>
  <v-row class="pb-4">
    <v-col cols="12" class="text-left header header-3 pt-0 pb-0">
      Consumos do Período Anterior
    </v-col>

    <v-col
      cols="12"
      class="text-left text text-normal pt-1 pb-0"
      v-if="
        this.billing_periods[this.selected_index + 1] &&
        this.billing_periods[0].start_date
      "
    >
      De
      {{ this.start_date_bp() }}
      a
      {{ this.end_date_bp() }}
    </v-col>
    <v-col
      cols="12"
      class="text-left text text-normal pt-1 pb-0"
      v-if="!this.billing_periods"
    >
      Sem dados.
    </v-col>
    <v-col class="pt-2">
      <v-card class="col-12 rounded-lg">
        <v-row>
          <v-col cols="12" class="text">
            <v-row>
              <!-- Consumption energy -->
              <v-col
                class="text-center right-liner"
                v-if="this.consumption_data_prev.totals"
              >
                <period-before-consumption-stat
                  :energy="true"
                  :stat_type="'Consumos Totais'"
                  :stat_value="this.consumption_data_prev.totals.total_energy"
                />
              </v-col>
              <!-- Consumption cost -->
              <v-col
                class="text-center"
                v-if="this.consumption_data_prev.totals"
              >
                <period-before-consumption-stat
                  :stat_type="'A Receber'"
                  :stat_value="`${this.consumption_data_prev.totals.total_cost} €`"
                />
              </v-col>
              <v-col
                cols="12"
                class="text"
                v-if="
                  this.selected_index == 0 &&
                  this.consumption_data_prev.consumptions[0]
                "
              >
                <!-- Charging cost table -->
                <charging-cost-table
                  :users="this.consumption_data_prev.consumptions"
                />
              </v-col>
              <v-col
                cols="12"
                class="text"
                v-if="
                  this.selected_index == 0 &&
                  !this.consumption_data_prev.consumptions[0]
                "
              >
                <!-- Charging cost table no data -->
                <charging-cost-table-no-data />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="pt-3">
        <v-col style="font-size: 12px">
          <span v-if="this.consumption_data_prev.confirmed == false">
            <router-link
              @click="handleChangeTab(3)"
              :to="{ path: '/dashboard/support', query: { cat: 1 } }"
            >
              <b style="color: black">custos estimados</b>
            </router-link></span
          ><span
            v-if="this.consumption_data_prev.confirmed == true"
            class="font-weight-bold"
            >custos confirmados</span
          >, incluindo IVA à taxa legal em vigor.
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import PeriodBeforeConsumptionStat from "@/components/dashboard/period-before-consumption-stat.vue";
import ChargingCostTable from "@/components/dashboard/charging-cost-table.vue";
import ChargingCostTableNoData from "./charging-cost-table-no-data.vue";
import { General } from "@/mixins/general";
import moment from "moment";

export default mixins(General).extend({
  name: "PeriodBeforeConsumptionManager",
  components: {
    PeriodBeforeConsumptionStat,
    ChargingCostTable,
    ChargingCostTableNoData,
  },
  methods: {
    start_date_bp() {
      return moment(
        this.billing_periods[this.selected_index + 1].start_date
      ).format("DD-MM-YYYY");
    },
    end_date_bp() {
      return moment(
        this.billing_periods[this.selected_index + 1].end_date
      ).format("DD-MM-YYYY");
    },
  },
});
</script>
