<template>
  <v-row>
    <v-col :class="edit == true ? 'd-flex justify-end' : ''" cols="12" class="pb-6">
      <v-btn depressed dense :height="this.height" :width="this.width" :outlined="this.inverted == true ? true : false"
        :color="'#47aea5'" :text-color="this.inverted == true ? '#47aea5' : '#fff'"
        :class="[textSize ? 'button-test' : 'charging-table-button', edit == true ? 'btn-choose-condo' : '']" rounded-md
        :loading="loading" :disabled="loading || disabled ? true : false" @click="callSubmitEvent" block>
        <template v-if="icon">
          <span>
            <v-icon color="#ffffff" class="send-email-icon">{{ icon }}</v-icon>
          </span>
        </template>
        <div :class="[
          disabled ? 'text-disabled' : '',
          inverted == true ? 'button-2' : 'button',
          textSize ? 'text-size' : 'button-text-1',
        ]">
          {{ title }}
        </div>
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "SubmitButton",
  props: ["title", "name", "disabled", "inverted", "textSize", "height", "width", "edit"],
  computed: {
    loading: function (): any {
      return this.$store.getters["buttonState"](this.name);
    },
  },
  methods: {
    callSubmitEvent(): void {
      this.$emit("callEvent", this.name);
    },
  },
});
</script>

<style lang="scss" scoped>
.btn-choose-condo {
  width: 89px !important;
  min-width: 89px !important;
  display: flex !important;

}

.text-size {
  font-size: 15px;
}

.text-disabled {
  color: #b1b1b1;
}

.button-test {
  max-width: 110px;
}

.send-email-icon {
  margin-right: 10px;
}
</style>
