var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:'tariffsForm' + _vm.index},[_c('div',[(this.edit != true)?_c('v-row',{staticClass:"mx-0 my-0 py-0 px-0",class:_vm.period == 1 ? 'pt-1' : ''},[(this.contract_data[0].plan > 0)?_c('v-col',{class:this.noMargins == true ? 'mx-0 px-0' : '',attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"field_key":'power_price',"period":_vm.periods,"index":_vm.index,"id":'power_price-'+_vm.index,"error":_vm.error,"title":'Indique o custo da potência em horas de ponta',"field_type":'float',"suffix":'€ / kVA',"bold":true,"required":true,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
          this.contract_data[0].schedule_type == 0 &&
          this.contract_data[0].plan == 0
        )?_c('v-col',{staticClass:"pt-2 pb-0",class:(this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null),attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"field_key":'t2_energy_price',"period":_vm.periods,"index":_vm.index,"id":'t2_energy_price-'+_vm.index,"title":'Indique o valor da tarifa de energia',"placeholder":'Valor da tarifa',"error":_vm.error,"required":true,"field_type":'float',"suffix":'€ / kWh',"bold":true,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
          this.contract_data[0].schedule_type > 0 &&
          this.contract_data[0].plan != 1
        )?_c('v-col',{staticClass:"pt-2 pb-4",class:(this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null),attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"bold":true,"error":_vm.error,"period":_vm.periods,"index":_vm.index,"id":'tarifas_energia-'+_vm.index,"title_alone":true,"required":true,"title":'Indique o valor das tarifas de Energia'},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),_c('v-col',{class:this.noMargins == true ? 'px-0 pb-0' : 'pb-0'},[_c('v-row',[(this.contract_data[0].plan == 1)?_c('v-col',{staticClass:"pt-0 pb-2 normal-label text-5",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"12"}},[_vm._v("Indique o valor das tarifas de Energia")]):_vm._e(),(this.contract_data[0].plan > 0)?_c('v-col',{staticClass:"pt-4 pb-0",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"field_key":'t1_energy_price',"period":_vm.periods,"index":_vm.index,"id":'t1_energy_price-'+_vm.index,"title":'Hora de Super-Vazio',"error":_vm.error,"required":true,"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.contract_data[0].schedule_type != 0 ||
              this.contract_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0",class:{'col-6': this.contract_data[0].plan != 0 && this.contract_data[0].plan != null}},[_c('form-textbox',{attrs:{"field_key":'t2_energy_price',"error":_vm.error,"period":_vm.periods,"index":_vm.index,"id":'t2_energy_price-'+_vm.index,"title":'Hora de Vazio',"required":true,"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.contract_data[0].schedule_type > 0 ||
              this.contract_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0"},[_c('form-textbox',{attrs:{"field_key":'t3_energy_price',"period":_vm.periods,"index":_vm.index,"id":'t3_energy_price-'+_vm.index,"error":_vm.error,"title":this.contract_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio',"field_type":'float',"required":true,"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.contract_data[0].schedule_type == 2 ||
              this.contract_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0"},[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t4_energy_price-'+_vm.index,"error":_vm.error,"field_key":'t4_energy_price',"title":'Hora de Ponta',"required":true,"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e()],1),(this.contract_data[0].plan == 1)?_c('v-row',[(this.contract_data[0].plan == 1)?_c('v-col',{staticClass:"pt-0 pb-2 normal-label text-5 mt-3",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"12"}},[_vm._v("Indique o valor das tarifas de Redes")]):_vm._e(),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-4' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t1_grid_price-'+_vm.index,"error":_vm.error,"field_key":'t1_grid_price',"title":'Hora de Super-Vazio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t2_grid_price-'+_vm.index,"error":_vm.error,"field_key":'t2_grid_price',"title":'Hora de Vazio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t3_grid_price-'+_vm.index,"field_key":'t3_grid_price',"title":this.contract_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio',"field_type":'float',"error":_vm.error,"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t4_grid_price-'+_vm.index,"field_key":'t4_grid_price',"error":_vm.error,"title":'Hora de Ponta',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1)],1):_vm._e()],1)],1):_vm._e(),(this.edit == true)?_c('v-row',{staticClass:"mx-0 my-0 py-0 px-0",class:_vm.period == 1 ? 'pt-1' : ''},[(this.edit_data[0].plan > 0)?_c('v-col',{class:this.noMargins == true ? 'mx-0 px-0' : '',attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"field_key":'power_price',"period":_vm.periods,"index":_vm.index,"id":'power_price-'+_vm.index,"error":_vm.error,"edit":true,"required":true,"title":'Indique o custo da potência em horas de ponta',"field_type":'float',"suffix":'€ / kVA',"bold":true,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
          this.edit_data[0].schedule_type == 0 && this.edit_data[0].plan == 0
        )?_c('v-col',{staticClass:"pt-2 pb-0",class:(this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null),attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"edit":true,"field_key":'t2_energy_price',"error":_vm.error,"period":_vm.periods,"index":_vm.index,"id":'t2_energy_price-'+_vm.index,"required":true,"title":'Indique o valor da tarifa de energia contratada',"placeholder":'Valor da tarifa',"field_type":'float',"suffix":'€ / kWh',"bold":true,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
          this.edit_data[0].schedule_type > 0 && this.edit_data[0].plan != 1
        )?_c('v-col',{staticClass:"pt-2 pb-4",class:(this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null),attrs:{"cols":"12"}},[_c('form-textbox',{attrs:{"bold":true,"period":_vm.periods,"index":_vm.index,"id":'tarifas_energia-'+_vm.index,"title_alone":true,"error":_vm.error,"required":true,"edit":true,"title":'Indique o valor das tarifas de Energia'},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),_c('v-col',{class:this.noMargins == true ? 'px-0 pb-0' : 'pb-0'},[_c('v-row',[(this.edit_data[0].plan == 1)?_c('v-col',{staticClass:"pt-0 pb-2 normal-label text-5",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"12"}},[_vm._v("Indique o valor das tarifas de Energia")]):_vm._e(),(this.edit_data[0].plan > 0)?_c('v-col',{staticClass:"pt-4 pb-0",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"edit":true,"field_key":'t1_energy_price',"required":true,"error":_vm.error,"period":_vm.periods,"index":_vm.index,"id":'t1_energy_price-'+_vm.index,"title":'Hora de Super-Vazio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.edit_data[0].schedule_type != 0 ||
              this.edit_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0",class:[
              this.noMargins == true ? 'mx-0' : '',
              this.edit_data[0].plan == '0' &&
              this.edit_data[0].schedule_type == '2'
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]},[_c('form-textbox',{attrs:{"edit":true,"field_key":'t2_energy_price',"period":_vm.periods,"index":_vm.index,"id":'t2_energy_price-'+_vm.index,"error":_vm.error,"title":'Hora de Vazio',"required":true,"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.edit_data[0].schedule_type > 0 || this.edit_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0",class:[
              this.noMargins == true ? 'mx-0' : '',
              this.edit_data[0].plan == '0' &&
              this.edit_data[0].schedule_type == '2'
                ? 'col-sm-4 col-12'
                : 'col-6',
            ],attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"field_key":'t3_energy_price',"period":_vm.periods,"index":_vm.index,"id":'t3_energy_price-'+_vm.index,"required":true,"error":_vm.error,"title":this.edit_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio',"edit":true,"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e(),(
              this.edit_data[0].schedule_type == 2 ||
              this.edit_data[0].plan != 0
            )?_c('v-col',{staticClass:"pt-4 pb-0",class:[
              this.noMargins == true ? 'mx-0' : '',
              this.edit_data[0].plan == '0' &&
              this.edit_data[0].schedule_type == '2'
                ? 'col-sm-4 col-12'
                : 'col-6',
            ],attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"period":_vm.periods,"index":_vm.index,"id":'t4_energy_price-'+_vm.index,"edit":true,"required":true,"error":_vm.error,"field_key":'t4_energy_price',"title":'Hora de Ponta',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1):_vm._e()],1),(this.edit_data[0].plan == 1)?_c('v-row',[(this.edit_data[0].plan == 1)?_c('v-col',{staticClass:"pt-0 pb-2 normal-label text-5 mt-3",class:this.noMargins == true ? 'mx-0' : '',attrs:{"cols":"12"}},[_vm._v("Indique o valor das tarifas de Redes")]):_vm._e(),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-4' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"edit":true,"period":_vm.periods,"index":_vm.index,"id":'t1_grid_price-'+_vm.index,"error":_vm.error,"field_key":'t1_grid_price',"title":'Hora de Super-Vazio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"edit":true,"period":_vm.periods,"index":_vm.index,"id":'t2_grid_price-'+_vm.index,"field_key":'t2_grid_price',"error":_vm.error,"title":'Hora de Vazio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"edit":true,"period":_vm.periods,"index":_vm.index,"id":'t3_grid_price-'+_vm.index,"field_key":'t3_grid_price',"error":_vm.error,"title":this.edit_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1),_c('v-col',{class:this.noMargins == true ? 'mx-0 pt-0' : '',attrs:{"cols":"6"}},[_c('form-textbox',{attrs:{"edit":true,"period":_vm.periods,"index":_vm.index,"id":'t4_grid_price-'+_vm.index,"field_key":'t4_grid_price',"error":_vm.error,"title":'Hora de Ponta',"field_type":'float',"suffix":'€ / kWh',"bold":false,"title_alone":false},on:{"warning":_vm.watchWarning,"checkMethod":_vm.checkSubmitButton}})],1)],1):_vm._e()],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }