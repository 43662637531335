<template>
  <v-tooltip
    max-width="200"
    color="#707070"
    top
    v-model="trigger"
    content-class="tooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-tooltip mr-2"
        x-small
        plan="false"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon @click="trigger = !trigger" x-medium color="#47aea5">
          mdi-help-circle
        </v-icon>
      </v-btn>
    </template>
    <span class="tooltip-span" v-html="helpText"></span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "ToolTip",
  props: ["helpText"],
  data() {
    return {
      trigger: undefined,
    };
  },
});
</script>

