<template>
  <v-row>
    <v-col cols="12" class="text table-header-area">
      <v-row class="text text-5 table-header">
        <v-col cols="6">Condóminos</v-col>
        <v-col cols="3">kWh</v-col>
        <v-col cols="3">A Pagar</v-col>
      </v-row>
    </v-col>
    <v-col class="text-center no-content-body">
      <div class="pt-10 pb-5 flex justify-center">
        <img
          class="no-charging-session-icon-img"
          src="@/assets/icons/icon-no-charge.png" />
      </div>
      <div class="text dull text-5 pb-5">
        Sem carregamentos efetuados este mês.
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "ChargingCostTabelNoData",
});
</script>

<style lang="scss" scoped>
.no-charging-session-icon-img {
  width: 30%;
  min-width: 50px;
}

@media screen and (min-width: 600px) {
  .no-charging-session-icon-img {
    width: 15%;
  }
}
</style>
