var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"mt-4 mb-3 text-center"},[(!this.profile || this.condos_response.selected_role != 'user')?_c('v-col',{staticClass:"pr-0"},[_c('v-btn',{staticClass:"button tab-inactive button-text-2 tab-round-left",class:{
      'tab-active': _vm.active_tab === 0 && !this.profile,
      'tab-active-profile': _vm.active_tab_profile === 0 && this.profile
    },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(0)}}},[_vm._v(" "+_vm._s(_vm.tab1)+" ")])],1):_vm._e(),_c('v-col',{class:!(!this.profile || _vm.tab1 != null) ? 'pr-0' : 'px-0'},[_c('v-btn',{staticClass:"button tab-inactive button-text-2",class:{
      'tab-active': _vm.active_tab === 1 && !this.profile,
      'tab-round-left': !(!this.profile || _vm.tab1 != null),
      'spacing tab-round-none': (!this.profile || _vm.tab1 != null),
      'tab-active-profile': _vm.active_tab_profile === 1 && this.profile
    },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(1)}}},[_vm._v(" "+_vm._s(_vm.tab2)+" ")])],1),_c('v-col',{staticClass:"pl-0"},[_c('v-btn',{staticClass:"button tab-inactive button-text-2 tab-round-right",class:{
      'tab-active': _vm.active_tab === 2 && !this.profile,
      'tab-active-profile': _vm.active_tab_profile === 2 && this.profile
    },attrs:{"depressed":"","block":""},on:{"click":function($event){return _vm.changeActiveTab(2)}}},[_vm._v(" "+_vm._s(_vm.tab3)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }