<template>
  <!-- Report area -->
  <v-row>
    <v-col cols="12" class="text-left header header-3 pt-0 pb-0">
      Gerar Relatório
    </v-col>
    <!-- Datepicker -->
    <v-col cols="12" class="pt-2">
      <bill-period-selector />
    </v-col>
    <!-- End DatePicker -->
    <!-- Submit -->
    <v-col cols="12">
      <submit-button
        :title="'Enviar relatório de consumos'"
        :icon="'mdi-send'"
        :name="'send-manager-report'"
        @callEvent="postSendEmailReport"
        :disabled="
          !consumption_data.billing_periods_options ||
          this.consumption_data.billing_periods_options.length == 0
        "
      />
    </v-col>
    <!-- End submit report -->
    <!-- Success modal -->
    <success-modal
      :modalName="'send-manager-report'"
      :alt="'report success'"
      :icon="'icon-report-sent.png'"
      :header="'Relatório Enviado!'"
      :text="'O relatório que solicitou foi enviado em formato Excel para a sua conta de email.'"
    />
    <!-- End success modal -->
  </v-row>
  <!-- End Report area -->
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import billPeriodSelector from "@/components/dashboard/bill-period-selector.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import SuccessModal from "@/components/general/success-modal.vue";

export default mixins(General).extend({
  components: { billPeriodSelector, SubmitButton, SuccessModal },
  name: "Report",
  methods: {
    async postSendEmailReport(btn_name: string): Promise<any> {
      const data = {
        uri: "email_report",
        start_date: this.s_date.send_format,
        end_date: this.e_date.send_format,
        btn_name: btn_name,
        show_success: false,
      };
      return this.$store.dispatch("sendPost", data).then(async (resp) => {
        this.stopLoadingShowModal(btn_name, resp ? true : false);
      });
    },
  },
});
</script>

