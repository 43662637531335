<template>
  <v-snackbar
    :color="snackbar.color"
    :dark="true"
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    :top="true"
  >
    <div class="font-size-inc"> 
      <span v-if="snackbar.percentage || snackbar.showButton" style="margin-right: 5px;">
        <v-icon color="white" class="warning-icon">
          mdi-alert-circle
        </v-icon>
      </span>
        {{ snackbar.message }} 
      <span v-if="snackbar.percentage" class="viewPercentage">{{ snackbar.percentage}}%</span>
      <span v-if="snackbar.showButton" class="viewButton"><a class="styleOfButton" href="/dashboard/tariff">Ver</a></span></div>
  </v-snackbar>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "Snackbar",
  watch: {
    snackbar: function (value: any) {
      if (value && value.show) {
        setTimeout(() => {
          if(this.snackbar.showButton){
            this.$store.dispatch("toggleSnackbarPastThirtyDays", null);
          }else if(this.snackbar.color=='green'){
            this.$store.dispatch("toggleSnackbar", {
              color: "green",
            });
          }else{
            this.$store.dispatch("toggleSnackbar", null);
          }
        }, this.snackbar.timeout - 2000);
      }
    },
  },
});
</script>
<style scoped lang="scss">
.font-size-inc {
  font-size: 15px;
  text-align: center;
}
.viewPercentage{
  float: right;
  padding-top: 2px;
}
.viewButton{
  float: right;
  padding-top: 1px;
  margin-left: 10px;
}
.styleOfButton{
  text-decoration: underline;  
  color: inherit;
}
</style>