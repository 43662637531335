var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"text-left header header-3 pt-0 pb-0",attrs:{"cols":"12"}},[_vm._v(" Consumos do Período Anterior ")]),(
      this.billing_periods[this.selected_index + 1] &&
      this.billing_periods[0].start_date
    )?_c('v-col',{staticClass:"text-left text text-normal pt-1 pb-0",attrs:{"cols":"12"}},[_vm._v(" De "+_vm._s(this.start_date_bp())+" a "+_vm._s(this.end_date_bp())+" ")]):_vm._e(),(!this.billing_periods)?_c('v-col',{staticClass:"text-left text text-normal pt-1 pb-0",attrs:{"cols":"12"}},[_vm._v(" Sem dados. ")]):_vm._e(),_c('v-col',{staticClass:"pt-2"},[_c('v-card',{staticClass:"col-12 rounded-lg"},[_c('v-row',[_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('v-row',[(this.consumption_data_prev.totals)?_c('v-col',{staticClass:"text-center right-liner"},[_c('period-before-consumption-stat',{attrs:{"energy":true,"stat_type":'Consumos Totais',"stat_value":this.consumption_data_prev.totals.total_energy}})],1):_vm._e(),(this.consumption_data_prev.totals)?_c('v-col',{staticClass:"text-center"},[_c('period-before-consumption-stat',{attrs:{"stat_type":'A Receber',"stat_value":`${this.consumption_data_prev.totals.total_cost} €`}})],1):_vm._e(),(
                this.selected_index == 0 &&
                this.consumption_data_prev.consumptions[0]
              )?_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('charging-cost-table',{attrs:{"users":this.consumption_data_prev.consumptions}})],1):_vm._e(),(
                this.selected_index == 0 &&
                !this.consumption_data_prev.consumptions[0]
              )?_c('v-col',{staticClass:"text",attrs:{"cols":"12"}},[_c('charging-cost-table-no-data')],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticStyle:{"font-size":"12px"}},[(this.consumption_data_prev.confirmed == false)?_c('span',[_c('router-link',{attrs:{"to":{ path: '/dashboard/support', query: { cat: 1 } }},on:{"click":function($event){return _vm.handleChangeTab(3)}}},[_c('b',{staticStyle:{"color":"black"}},[_vm._v("custos estimados")])])],1):_vm._e(),(this.consumption_data_prev.confirmed == true)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("custos confirmados")]):_vm._e(),_vm._v(", incluindo IVA à taxa legal em vigor. ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }