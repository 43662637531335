var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cd-wrapper"},[_c('div',{staticClass:"cd-body-wrapper"},[_c('div',{staticClass:"d-flex align-center justify-space-between mx-4 mt-2"},[_c('div',{staticClass:"clickable d-flex align-center justify-center",class:_vm.nextDisabled == true ? 'blocks' : 'blocks-enabled',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeBillingPeriod('next')}}},[_c('button',{staticClass:"pl-1 clickable"},[_c('figure',{staticClass:"left",class:_vm.nextDisabled == true ? 'chevron' : 'chevron-enabled'})])]),_c('span',{staticClass:"date-time font-weight-bold"},[_vm._v(" "+_vm._s(_vm.billingPeriodStartDate.date() + " " + _vm.translateMonthName(_vm.billingPeriodStartDate.month() + 1))+" - "+_vm._s(_vm.billingPeriodEndDate.date() + " " + _vm.translateMonthName(_vm.billingPeriodEndDate.month() + 1))+" ")]),_c('div',{staticClass:"clickable d-flex align-center justify-center",class:_vm.prevDisabled == true ? 'blocks' : 'blocks-enabled',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeBillingPeriod('prev')}}},[_c('button',{staticClass:"pr-1 clickable"},[_c('figure',{staticClass:"right",class:_vm.prevDisabled == true ? 'chevron' : 'chevron-enabled'})])])]),_c('div',{staticClass:"d-flex align-center justify-space-around",class:_vm.$vuetify.breakpoint.md ? 'justify-space-between' : ''},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('v-row',{staticClass:"overflow-y-auto container-dates overflow-x-hidden px-0 py-o mx-0 my-0",class:{
      'overflow-auto': _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
    }},[_c('v-col',{staticClass:"px-0 mx-0 my-0 py-0"},[_c('v-row',{staticClass:"px-0 mx-0 my-0 py-0"},_vm._l((_vm.$vuetify.breakpoint.sm ||
            _vm.$vuetify.breakpoint.xs
            ? this.otherDays
            : this.displayedPages),function(value,index){return _c('v-col',{key:index,staticClass:"px-5",class:{
  'container-dates heights px-2':
    _vm.$vuetify.breakpoint.md ||
    _vm.$vuetify.breakpoint.lg ||
    _vm.$vuetify.breakpoint.xl,
},attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mt-4 ml-0 font-weight-medium justify-start"},[_vm._v(" "+_vm._s(value[0].month() + 1 > 12 ? _vm.translateMonth(1) : _vm.translateMonth(value[0].month() + 1))+" "+_vm._s(value[0].month() + 1 > 12 ? value[0].year() + 1 : value[0].year())+" ")]),_c('section',[_c('div',{staticClass:"cd-days",attrs:{"data-transition":"month-change"}},_vm._l((value),function(day,i){return _c('button',{key:i,staticClass:"d-flex justify-center",class:_vm.calcDayClass(day),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeCustomDateRange(day)}}},[_c('span',[_vm._v(_vm._s(day.format("D")))]),_c('figure',{style:({
                    background: _vm.primaryColor,
                  })})])}),0)])])}),1)],1),_c('v-col',{staticClass:"px-2 pt-3 pb-3 d-none d-md-flex justify-center",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center"},[(this.otherDays.length > 2)?_c('div',{staticClass:"d-flex"},_vm._l((this.totalPages),function(n){return _c('div',{key:n},[_c('div',{staticClass:"d-flex clickable mr-2",class:n == _vm.currentPage ? 'dot2' : 'dot',on:{"click":function($event){return _vm.getPage(n)}}})])}),0):_vm._e(),_c('v-col',{staticClass:"bottom-liner-v2",attrs:{"cols":"12"}})],1)],1),_c('div',{staticClass:"d-flex my-2 ml-5 font-weight-medium justify-start justify-md-center",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Seleção: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(`${this.selectedStartDate.date()} ${this.translateMonth( this.selectedStartDate.month() + 1 )} - ${this.selectedEndDate ? this.selectedEndDate.date() : "Data Fim" } ${this.translateMonth( this.selectedEndDate ? this.selectedEndDate.month() + 1 : null )}`)+" ")])]),_c('div',{staticClass:"d-flex justify-center mb-5 px-5",staticStyle:{"width":"100%"}},[_c('button',{staticClass:"reset mr-2 text-4 clickable",on:{"click":function($event){return _vm.resetToBillingPeriod()}}},[_vm._v(" Repor Período Atual ")]),_c('button',{staticClass:"submit text-4 clickable",on:{"click":function($event){return _vm.setSelectedPeriod(_vm.selectedStartDate, _vm.selectedEndDate)}}},[_vm._v(" Confirmar Seleção ")])])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-md-none d-flex align-center justify-space-around days mt-2"},[_c('span',{staticClass:"ml-3"},[_vm._v("Dom")]),_c('span',[_vm._v("Seg")]),_c('span',[_vm._v("Ter")]),_c('span',[_vm._v("Qua")]),_c('span',[_vm._v("Qui")]),_c('span',[_vm._v("Sex")]),_c('span',{staticClass:"mr-3"},[_vm._v("Sab")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-none d-md-flex align-center justify-space-between days1 px-7 mt-2"},[_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Dom")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Seg")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Ter")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Qua")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Qui")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Sex")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Sab")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-none d-md-flex align-center justify-space-between days1 px-7 mt-2"},[_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Dom")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Seg")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Ter")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Qua")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Qui")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Sex")]),_c('span',{staticClass:"day d-flex justify-center"},[_vm._v("Sab")])])
}]

export { render, staticRenderFns }